import { FC } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './whoweare-section.scss'
import ReplyLogo from './../../assets/logos/glue-logo-no-text-white.png'

const WhoWeAre:FC = () => {
  return (
    <>
        <GlueBaseSection>
            <div className="section-container">
                <div className="whoweare-title-container">
                  <h1>WHO WE ARE</h1>
                  <a href="https://www.reply.com/en" target="_blank" rel="noopener noreferrer"><button className="reply-button">
                    <div className="reply-find-out-more">Find out More </div>
                    <div className="reply-button-content">
                      <div className="reply-button-text">
                        <div className="reply-text-spans"><span>Part of the</span> 
                        <span>Reply Network</span>
                        </div>
                      </div>
                      <img src={ReplyLogo} alt="reply small white logo"></img>
                    </div>    
                  </button></a>
                </div>
                <div className="whoweare-description-container">
                  <p className="whoweare-intro">
                    We specialise in creating cutting-edge solutions within our core four practice areas: 
                    Strategy & Architecture, Product Transformation, Integration & Automation, Data Delivery.
                  </p>
                  <p className="whoweare-desc">
                    We are trusted by public and private sector clients to transform their digital experience. 
                    We provide end-to-end services and build a holistic technology ecosystem that drives innovation and growth.
                  </p>
                  <p className="whoweare-desc">
                    At Glue Reply, we drive your business forward by enabling transformational change across technology and operations. 
                  </p>
                </div>
            </div>
        </GlueBaseSection>
    
    </>
  )
}

export default WhoWeAre