export enum GluePages {
    HOMEPAGE,
    SERVICES,
    WORK,
    INSIGHTS,
    CAREERS,
    ABOUTUS,
    PARTOFREPLY,
    PEOPLE,
    COMPANY,
    ENVIRONMENT
}