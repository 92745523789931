import { FC, useEffect } from 'react'
import GluePage from '../components/glue-page/page'
import { GluePages } from '../model/glue-pages'
import './services.scss'
import { LeftCard, RightCard } from '../components/services-cards/services-card'
import EnablerSection from '../components/backend-services/enabler-section'
import GlueBaseSection from '../components/common-components/layouts/base-section'

// STRATEGY AND ARCHITECTURE IMPORTS
import StrategyCardImage from '../assets/services-page/strategy-architecture.webp'
import StrategyIcon1 from '../assets/services-page/detail-icons/strategy-architecture-1.svg'
import StrategyIcon2 from '../assets/services-page/detail-icons/strategy-architecture-2.svg'
import StrategyIcon3 from '../assets/services-page/detail-icons/strategy-architecture-3.svg'
import StrategyIcon4 from '../assets/services-page/detail-icons/strategy-architecture-4.svg'
import StrategyIcon5 from '../assets/services-page/detail-icons/strategy-architecture-5.svg'
import StrategyIcon6 from '../assets/services-page/detail-icons/strategy-architecture-6.svg'

// PDD IMPORTS
import ProductTransformationImage from '../assets/services-page/product-transformation.webp'
import PDDIcon1 from '../assets/services-page/detail-icons/PDD-Icon-1.svg'
import PDDIcon2 from '../assets/services-page/detail-icons/PDD-Icon-2.svg'
import PDDIcon3 from '../assets/services-page/detail-icons/PDD-Icon-3.svg'
import PDDIcon4 from '../assets/services-page/detail-icons/PDD-Icon-4.svg'
import PDDIcon5 from '../assets/services-page/detail-icons/PDD-Icon-5.svg'

// INTEGRATION IMPORTS
import IntegrationImage from '../assets/services-page/Integration.webp'
import IntegrationIcon1 from '../assets/services-page/detail-icons/integration-1.svg'
import IntegrationIcon2 from '../assets/services-page/detail-icons/integration-2.svg'
import IntegrationIcon3 from '../assets/services-page/detail-icons/integration-3.svg'
import IntegrationIcon4 from '../assets/services-page/detail-icons/integration-4.svg'
import IntegrationIcon5 from '../assets/services-page/detail-icons/integration-5.svg'
import IntegrationIcon6 from '../assets/services-page/detail-icons/integration-6.svg'
import IntegrationIcon7 from '../assets/services-page/detail-icons/integration-7.svg'

// DATA STRATEGY IMPORTS
import DataStrategyImage from '../assets/services-page/data-strategy-image.webp'
import DataStrategyIcon1 from '../assets/services-page/detail-icons/data-1.svg'
import DataStrategyIcon2 from '../assets/services-page/detail-icons/data-2.svg'
import DataStrategyIcon3 from '../assets/services-page/detail-icons/data-3.svg'
import DataStrategyIcon4 from '../assets/services-page/detail-icons/data-4.svg'
import DataStrategyIcon5 from '../assets/services-page/detail-icons/data-5.svg'
import DataStrategyIcon6 from '../assets/services-page/detail-icons/data-6.svg'
import DataStrategyIcon7 from '../assets/services-page/detail-icons/data-7.svg'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../utils/analyticsRequest'

export const GlueServices: FC = () => {

    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-services", "Services", cookies)    
      });
      
    const services = [
        {
            imageSrc: StrategyCardImage,
            description: "Crafting visionary strategies and robust architectures that drive sustainable growth.",
            title: "Strategy & Architecture",
            extraDetails: "As one of the UK’s leading system architecture consultancies, our expertise blends well-established frameworks and toolkits to accelerate delivery. With our extensive architectural capabilities, we offer our customers a full suite of services that unites business objectives with IT infrastructure. We have been recognised by Gartner for our approach to architecture, specifically our focus on customer, outcomes and value.",
            detailsIcons: [StrategyIcon1, StrategyIcon2, StrategyIcon3, StrategyIcon4, StrategyIcon5, StrategyIcon6],
            iconDescriptions: ["Demand Management", "Technology Assessment", "Business Capability Modelling", "IT Strategy Development", "Enterprise Architecture", "Risk Management"],
            position: true
        },
        {
            imageSrc: ProductTransformationImage,
            description: "Creating intuitive and impactful services that elevate user experiences.",
            title: "Product Transformation",
            extraDetails: "We start our process with a detailed understanding of the problem space and industry context, developing customized solutions that cater to user needs. Applying a User-Centered Design approach, combined with rapid prototyping and agile user testing, we integrate early-stage feedback to ensure our solutions are relevant and keep users at the forefront. To convert innovative ideas into successful outcomes, a focused and rigorous delivery process is essential. Glue Reply’s agile team, consisting of more than 50 practitioners, is committed to providing advanced products that meet our clients' demands on time, within budget, and to the highest quality standards.",
            detailsIcons: [PDDIcon1, PDDIcon2, PDDIcon3, PDDIcon4, PDDIcon5],
            iconDescriptions: ["Accelerated Discovery", "Rapid Alpha Prototyping", "Agile Enablement", "Service Transition Management", "End to End Digital Delivery GDS"],
            position: false
        },
        {
            imageSrc: IntegrationImage,
            description: "Connecting systems and automating processes to enhance efficiency and drive innovation.",
            title: "Integration & Automation",
            extraDetails: "Our advanced integration and automation solutions provide clients with a resilient IT infrastructure, giving them the ability and confidence to excel. We apply our in-depth expertise and establish vendor agnostic foundations to streamline operations, optimize workflows, and unlock new opportunities through automation. As an established premier partner with integration technologies, we guide our clients through the entire project life-cycle. ",
            detailsIcons: [IntegrationIcon1, IntegrationIcon2, IntegrationIcon3, IntegrationIcon4, IntegrationIcon5, IntegrationIcon6, IntegrationIcon7],
            iconDescriptions: ["iPaaS", "API Management", "Cloud Services", "Transition / Mobilise", "Analytics & AI Enabled Insights", "Platform & Tooling, Design & Setup", "Traditional ESB"],
            position: true
        },
        {
            imageSrc: DataStrategyImage,
            description: "Structuring data intelligently to unlock powerful insights and inform strategic decisions.",
            title: "Data Delivery",
            extraDetails: "We partner with organisations to enhance the value of their data, facilitating the development and delivery of data-driven services, products and solutions. From shaping data strategy, to delivering real-time data and AI analytics, our offerings enable businesses to be in control of their data. Our approach to data has been leveraged across all leading industries, forming the backbone of many global enterprises including FTSE 100 companies and critical defence institutions.",
            detailsIcons: [DataStrategyIcon1, DataStrategyIcon2, DataStrategyIcon3, DataStrategyIcon4, DataStrategyIcon5, DataStrategyIcon6, DataStrategyIcon7],
            iconDescriptions: ["Data Architecture", "Data Transformation and Modernisation", "Data Engineering", "Data Warehousing & Databases", "Data Security", "Data Strategy", "Real Time Data & AI Analytics"],
            position: false
        }
    ]

    return (
        <GluePage headerLocation={GluePages.SERVICES}>
            <GlueBaseSection>
                <div>
                    <h1 className='services-title'>WHAT WE DO</h1>
                </div>
                    {services.map((service, index) => (
                        service.position ? (
                            <LeftCard
                                key={index}
                                title={service.title}
                                description={service.description}
                                imageSrc={service.imageSrc}
                                extraDetails={service.extraDetails}
                                detailsIcons={service.detailsIcons}
                                iconDescriptions={service.iconDescriptions}
                                position={service.position} />
                        ) : (<RightCard
                            key={index}
                            title={service.title}
                            description={service.description}
                            imageSrc={service.imageSrc}
                            extraDetails={service.extraDetails}
                            detailsIcons={service.detailsIcons}
                            iconDescriptions={service.iconDescriptions}
                            position={service.position} />)
                    ))}
                    <EnablerSection title="Our Enablers" description='Strategy and Planning, Support & Maintenance and Product Centric Change are our common facilitators that enable us to successfully deliver value and real outcomes for our clients.' />
                
            </GlueBaseSection>
        </GluePage>
    )
}

