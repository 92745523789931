// base-section.tsx
import { FC, PropsWithChildren } from 'react'
import './base-section.scss'
import styled from 'styled-components'

type GlueBaseSectionProps = PropsWithChildren & {
  backgroundImage?: string
  applyBackgroundBlackOverlay?: boolean
  backgroundColor?: string
  id?: string
}

interface TransientStyledSectionProps {
  $backgroundImage?: string,
  $setBackGroundWithOverlayIfRequred?: string,
  $backgroundColor?: string
}

const StyleSectionWithBackground = styled.div<TransientStyledSectionProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${(props) => (props.$backgroundImage ? props.$setBackGroundWithOverlayIfRequred : props.$backgroundColor)};
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    background-blend-mode: overlay;
`;

const GlueBaseSection: FC<GlueBaseSectionProps> = ({children, backgroundImage, applyBackgroundBlackOverlay, backgroundColor, id}) => {
  const backgroundColorToApply = backgroundColor ? backgroundColor : "white"
  const setBackGroundWithOverlayIfRequred = applyBackgroundBlackOverlay ? `url(${backgroundImage}),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));` : `url(${backgroundImage})`
  
  return (
    <StyleSectionWithBackground $backgroundImage={backgroundImage} $setBackGroundWithOverlayIfRequred={setBackGroundWithOverlayIfRequred} $backgroundColor={backgroundColorToApply}>
      <div className='base-section-content' id={id}>
        {children}
      </div>
    </StyleSectionWithBackground>
  )
}

export default GlueBaseSection
