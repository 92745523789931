import React, { FC } from "react";
import './join-us-comp.scss';
import backgroundImage from '../../../assets/careers-page/background-join-us.png';

interface CardProps {
    title: string;
    description: string;
  }
  
  const Card: React.FC<CardProps> = ({ title, description }) => {
    const handleApply = () => {
      console.log(`Applying for ${title}`);
    };
  
    return (
      <article className="card">
        <h2 className="card__title">{title}</h2>
        <p className="card__description">{description}</p>
        <button className="card__apply-button" onClick={handleApply}>
          <a  href="https://www.reply.com/en/about/careers/uk/job-search?country=uk&company=glue_reply&role=student">Apply Now</a>
        </button>
      </article>
    );
  };

  const DesktopJoinCards:FC<{cardsData:CardProps[]}> = ({cardsData}) => {

    return(
      <div className="join-us-section__card-container">
            {cardsData.map((card, index) => (
              <Card key={"join-us-"+index} title={card.title} description={card.description} />
            ))}
      </div>
    )
  }

  
  const JoinUs: React.FC = () => {


    const cardsData: CardProps[] = [
      {
        title: "Students",
        description:
          "Currently studying and looking to gain real-world experience? Explore our internships, apprenticeships & placements!",
      },
      {
        title: "Graduate Schemes",
        description:
          "Successfully completed your studies? Start your professional future with us!",
      },
      {
        title: "Experienced Professionals",
        description:
          "Looking to use your experience to take on fresh challenges? Join us at Glue Reply!",
      },
    ];
  
    return (
      <section className="join-us-section">
        <img
          className="join-us-section__background"
          loading="lazy"
          src={backgroundImage}
          alt=""
        />
        <div className="join-us-section__content">
          <header className="join-us-section__header">
            <h1 className="join-us-section__title">JOIN US</h1>
            <p className="join-us-section__subtitle">
              Strategy, Creativity and Technology are at the core of every Glue
              Reply project. Explore our opportunities and help us build a digital
              future.
            </p>
          </header>
          <DesktopJoinCards cardsData={cardsData}/>
        </div>
      </section>
    );
  };
  
  export default JoinUs;