import React, { useState } from 'react';
import './diversity.scss';
import DiversityBox from './diversity-box';
import GlueBaseSection from "../../common-components/layouts/base-section";
import { PeopleSectionId } from '../../../model/section-ids';

const Diversity: React.FC = () => {
  const [expandedBoxes, setExpandedBoxes] = useState<{ [key: string]: boolean }>({
    global: false,
    opendoor: false,
    inclusion: false,
  });

  const handleBoxClick = (box: string) => {
    setExpandedBoxes(prevState => ({
      ...prevState,
      [box]: !prevState[box],
    }));
  };

  return (
    <GlueBaseSection id={PeopleSectionId.Diversity}>
      <div className="diversity-background">
        <div className="diversity-overlay">
          <h2>Diversity, Equality & Inclusion</h2>
          <div className="diversity-container">
            {['global', 'opendoor', 'inclusion'].map((box) => (
              <DiversityBox
                key={box}
                box={box}
                isExpanded={expandedBoxes[box]}
                handleBoxClick={handleBoxClick}
              />
            ))}
          </div>
        </div>
      </div>
    </GlueBaseSection>
  );
};

export default Diversity;
