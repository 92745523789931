import { FC } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './our-clients-section.scss'
import OurClientsBg from './../../assets/our-work/our-work-header-clients.png'
import ClientCarousel from "../common-components/carousels/clients-carousel"

export const ClientsSection: FC = () => {
    return(
        <>
            <GlueBaseSection backgroundImage={OurClientsBg} applyBackgroundBlackOverlay={true}>
                    <div className="clients-container">
                        <h1> OUR CLIENTS</h1>
                        <h2> Long Term Engagements</h2>
                        <ClientCarousel/>
                    </div>
            </GlueBaseSection>
        </>

    )
}