import { InsightArrayType } from "../../model/insights";

let baseURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : process.env.REACT_APP_PRODUCTION_STRAPI
let imageURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : '';

const buildFilters = (includeNonSustainable: Boolean, includeSustainable: Boolean, homePageOnly: Boolean, contentFilter?: string, serviceFilter?: string, descriptionFilter?: string) => {
  let filters = '&filters[backgroundImage][$notNull]=true';
  // Home page filter
  if (homePageOnly) return filters + '&filters[isOnHomepage]=true';

  // Sustainability filter
  if (includeNonSustainable && !includeSustainable) filters += '&filters[isSustainable]=false';
  else if (!includeNonSustainable && includeSustainable) filters += '&filters[isSustainable]=true';

  // Description filter (overrides content and service filters)
  if (descriptionFilter) filters = `&filters[linkDisplayedText][$containsi]=${encodeURIComponent(descriptionFilter)}`;
  else {  // Content and service filters
    if (contentFilter) filters += `&filters[content][$eqi]=${encodeURIComponent(contentFilter)}`;
    if (serviceFilter) filters += `&filters[service][$eqi]=${encodeURIComponent(serviceFilter)}`;  
  }
  return filters;
};

const buildSortingParams = (homePageOnly: Boolean) => {
  return homePageOnly ? '' : '&sort[0]=isPinned:desc&sort[1]=publishedAt:desc';
};

const buildPaginationParams = (limit = 3, offset = 0) => {
  return `&pagination[start]=${offset}&pagination[limit]=${limit}`;
};

export const fetchInsights = async (limit = 3, offset = 0, includeNonSustainable = true,includeSustainable = true, homePageOnly = false,contentFilter?: string, serviceFilter?: string, descriptionFilter?: string) => {
  let endpoint = `${baseURL}/api/insights?populate=*`;
  endpoint += buildFilters(includeNonSustainable, includeSustainable, homePageOnly, contentFilter, serviceFilter, descriptionFilter)
  endpoint += buildSortingParams(homePageOnly)
  endpoint += buildPaginationParams(limit, offset)

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (!response.ok) throw new Error('Failed to fetch insights');
  
      const data = await response.json();
    //   Mapping

      const formatInsights: InsightArrayType = {
        insights: 
          data.data.map((item: any) => ({
          image: imageURL + item.attributes.backgroundImage.data.attributes.url,
          borderAndText: item.attributes.borderAndTextColourCode,
          linkText: item.attributes.linkDisplayedText,
          linkTarget: item.attributes.linkURL,
          insightType: item.attributes.content,
          serviceType: item.attributes.service
          })),
        totalCount: data.meta.pagination.total
      }
  
      return formatInsights;
    } catch (error) {
      console.error("Error fetching insights:", error);
      throw error;
    }
  };