import { FC } from "react"
import { SubmenuColumn } from "../submenu-column"
import "./about-us-submenu.scss"
import {  CompanySectionId, EnvironmentSectionId, PeopleSectionId } from "../../../../model/section-ids"

type AboutusDropdownProps = {
    updateScrollTargetElementOnDrodown: React.Dispatch<React.SetStateAction<string>>
}

export const AboutDropdownMenu: FC<AboutusDropdownProps> = ({updateScrollTargetElementOnDrodown}) => {

    return(
        <>
            <div className="about-us-submenu">
                <SubmenuColumn title="People" titleTarget="/glue-people" links={["Culture","Continuous Learning","Diversity & Inclusion", "Life At Glue"]} linksTarget={[PeopleSectionId.Culture, PeopleSectionId.ContiniousLearning, PeopleSectionId.Diversity, PeopleSectionId.LifeAtGlue]} />
                <SubmenuColumn title="Environment" titleTarget="/glue-environment" links={["We Care","Net Zero","Sustainability Insights"]} linksTarget={[EnvironmentSectionId.WeCare, EnvironmentSectionId.NetZero, EnvironmentSectionId.Sustainability]} />
                <SubmenuColumn title="Company" titleTarget="/glue-company" links={["About Glue Reply", "About Reply Network", "Events"]} linksTarget={[ `/${CompanySectionId.AboutGlue}`, 'https://www.reply.com/en', 'https://www.reply.com/en/xchange']} />           
            </div>
        </>
    )
}