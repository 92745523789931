import React, { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useMemo, useState } from 'react'
import { GluePages } from '../../model/glue-pages'
import { InsightArrayType, InsightsType } from '../../model/insights'
import { insightCards } from '../../model/insights-cards'
import { CaseStudy } from '../../model/case-studies'

export interface GlueState {
    elementToScroll: string
    currentPage: GluePages
    insights: InsightsType[],
    caseStudies: CaseStudy[]
    filteredInsights: InsightArrayType,
    setOpenContact: Dispatch<SetStateAction<boolean>> | undefined,
    formTitle: string
    isRFPEmail: boolean
}

interface GlueStateContextValue {
    glueState: GlueState
    setGlueState: Dispatch<SetStateAction<GlueState>>
}

const defaultGlueState: GlueState = {
    elementToScroll:"navbar",
    currentPage: GluePages.HOMEPAGE,
    insights: [],
    caseStudies: [],
    filteredInsights: insightCards,
    setOpenContact: undefined,
    formTitle: "Send us a message",
    isRFPEmail: false 
}

const GlueStateContext = createContext<GlueStateContextValue>({
    glueState: defaultGlueState,
    setGlueState: () => {}
})

const GlueStateContextProvider: FC<PropsWithChildren> = (props: any) => {
    const [glueState, setGlueState] = useState<GlueState>(defaultGlueState);
    const glueContext = useMemo(() => ({glueState, setGlueState}), [glueState])
    return(
        <GlueStateContext.Provider value={glueContext}>
            {props.children}
        </GlueStateContext.Provider>
    );
}

export {GlueStateContext, GlueStateContextProvider}