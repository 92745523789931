import { useNavigate } from "react-router-dom";
import { CommonPageSectionsId } from "../../../model/section-ids";
import { GlueStateContext } from "../../../utils/context/glue-context";
import "./button.scss"
import { FC, useContext } from "react";
import Gradients from "../../../assets/services-page/gradients";

interface ButtonProps {
  text: string;
  scrollToTargetContainer?: string
  targetUrl?: string;
  isTargetExternal: boolean
  setTextWhite: boolean
  onClickEvent?: (arg:any) => void;
}


export const GlueGradientButton: FC<ButtonProps> = (props) => {
    const navigate = useNavigate()
    const {glueState} = useContext(GlueStateContext)
    const navigateAndScrollToTarget = () => {
        if(props.scrollToTargetContainer){
            glueState.elementToScroll = props.scrollToTargetContainer
        }
        if(props.targetUrl){
            navigate(props.targetUrl)
        }
        
    }

    const setWhiteOrBlack = props.setTextWhite ? 'white' : 'black';
    

    return (
        <>
            {props.isTargetExternal && <a href={props.targetUrl} className="button-container">
                <button className={`glue-gradient-button-${setWhiteOrBlack}`}>{props.text}</button>
            </a>}
            {!props.isTargetExternal && <p className="button-container">
                <button className={`glue-gradient-button-${setWhiteOrBlack}`} onClick={()=>{props.onClickEvent? props.onClickEvent(0) : navigateAndScrollToTarget()}}>{props.text}</button>
            </p>}
        </>
    );
};

export const HeaderArrowButton: FC = () => {
    function scrollToTargetElement() {
        const targetElement = document.getElementById(CommonPageSectionsId.MAINCONTENT)
        targetElement?.scrollIntoView({behavior:"smooth"})
    }

    return (
        <>
            <button className="arrow-button" onClick={()=>{scrollToTargetElement()}}>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <Gradients />
                    <circle id="circle" opacity="1" cx="25" cy="25" r="24.5" transform="rotate(90 25 25)" fill="red" fillOpacity="0" stroke="none"/>
                    <path id="arrow" d="M38 17L25.6238 33L13 17" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                </svg>
            </button>
        </>
    )
}