import React, { useRef, useEffect, SetStateAction, Dispatch, FC } from "react";
import "./base-card.scss";
import GlueBaseSection from "../common-components/layouts/base-section";
import Gradients from "../../assets/services-page/gradients";
import { isMobile } from "../../utils/determine-mobile-tablet";
import useWindowDimensions from "../../utils/window-dimensions";
import styled from "styled-components";

interface BaseCardProps {
    imageSrc: string;
    description: string;
    title: string;
    extraDetails: string;
    detailsIcons: Array<string>;
    iconDescriptions: Array<string>;
    position: 'left' | 'right';
}

interface DesktopAndMobileBaseCardProps {
    imageSrc: string,
    description: string,
    title: string,
    position: string,
    visible: string,
    expanded: boolean,
    setVisible: Dispatch<SetStateAction<string>>,
    handleExpandClick: () => void
}


const DesktopBaseCard:FC<DesktopAndMobileBaseCardProps> = ({imageSrc, description, title, position, expanded, visible, setVisible, handleExpandClick}) => {
    return(
        <> <div className="flex-container">
            {position === 'right' && (
                <div className="image-column">
                    <img
                        className="main-image"
                        loading="lazy"
                        src={imageSrc}
                        alt="Card visual"
                    />
                </div>
            )}
            <div className="text-column">
                <div className="text-content">
                    <p className="description">{description}</p>
                    <h2 className="title">{title}</h2>
                    <div className="expand-button-div">
                        <button
                            className="in-card-expand-button"
                            onClick={handleExpandClick}
                            onMouseEnter={() => setVisible("visible")}
                            onMouseLeave={() => setVisible("not-visible")}
                        >
                            <svg className="expand-icon" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <Gradients />
                                <circle id="circle" opacity="1" cx="25" cy="25" r="24.5" transform="rotate(90 25 25)" fill="none" fillOpacity="0" stroke="black"/>
                                <path id="horizontal" d="M15 24H35.095" stroke="black" strokeWidth="3" strokeLinecap="round"/>
                                {expanded ? null : <path id="vertical" d="M25 35.0947V13.9997" stroke="black" strokeWidth="3" strokeLinecap="round"/>}
                            </svg>
                        </button>
                        <label htmlFor="in-card-expand-button" className={visible}>
                            {expanded ? 'Show less' : 'Show more'}
                        </label>
                    </div>
                </div>
            </div>
            {position === 'left' && (
                <div className="image-column">
                    <img
                        className="main-image"
                        loading="lazy"
                        src={imageSrc}
                        alt="Card visual"
                    />
                </div>
            )}
        </div>
        </>
    )
}


interface StyledMobileProps {
    $backgroundImage:string
}

const StyledMobileCardContainer = styled.div<StyledMobileProps>`
    background: url(${p => p.$backgroundImage}),linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5));
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    border-radius: 1rem;
    width:90%;
    height: 300px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    color: white;
    padding: 2rem;
    text-align: center;
    background-blend-mode: overlay;
`

const MobileBaseCard:FC<DesktopAndMobileBaseCardProps> = ({imageSrc, description, title, expanded, visible, setVisible, handleExpandClick}) => {
    return(
        <>
            <StyledMobileCardContainer $backgroundImage={imageSrc}>
                <p className="description">
                    {description}
                </p>
                <h2 className="title">
                    {title}
                </h2>
                <button
                    className="in-card-expand-button"
                    onClick={handleExpandClick}
                    onMouseEnter={() => setVisible("visible")}
                    onMouseLeave={() => setVisible("not-visible")}
                >
                    <svg className="expand-icon" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <Gradients />
                        <circle id="circle" opacity="1" cx="25" cy="25" r="24.5" transform="rotate(90 25 25)" fill="grey" fillOpacity="0.5" stroke="white"/>
                        <path id="horizontal" d="M15 24H35.095" stroke="white" strokeWidth="3" strokeLinecap="round"/>
                        {expanded ? null : <path id="vertical" d="M25 35.0947V13.9997" stroke="white" strokeWidth="3" strokeLinecap="round"/>}
                    </svg>
                </button>
            </StyledMobileCardContainer>

        
        </>
    )


}

const BaseCard: FC<BaseCardProps> = ({
    imageSrc,
    description,
    title,
    extraDetails,
    detailsIcons,
    iconDescriptions,
    position,
}) => {
    const [expanded, setExpanded] = React.useState(false);
    const [visible, setVisible] = React.useState("not-visible");
    const contentRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    const isMobileMode = isMobile(useWindowDimensions().width)

    const handleExpandClick = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            if (expanded) {
                contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
                setTimeout(() => {
                    scrollToCard();
                }, 300); 
            } else {
                contentRef.current.style.maxHeight = "0px";
            }
        }
    }, [expanded]);

    const scrollToCard = () => {
        if (cardRef.current) {
            const rect = cardRef.current.getBoundingClientRect();
            const offset = -30; 
            if (rect.top < 0 || rect.bottom > window.innerHeight) {
                window.scrollTo({
                    top: rect.top + window.scrollY + offset,
                    behavior: "smooth",
                });
            }
        }
    };

    return (
        <GlueBaseSection>
            <section ref={cardRef} className={`services-card ${expanded ? 'expanded' : ''}`}>
                <div className="content-wrapper">
                   {!isMobileMode && <DesktopBaseCard imageSrc={imageSrc} description={description} title={title} position={position} visible={visible} expanded={expanded} setVisible={setVisible} handleExpandClick={handleExpandClick}/>}    
                    {isMobileMode && <MobileBaseCard imageSrc={imageSrc} description={description} title={title} position={position} visible={visible} expanded={expanded} setVisible={setVisible} handleExpandClick={handleExpandClick}/>}
                    
                    <div ref={contentRef} className={`expanded-content ${expanded ? 'expanded' : ''}`}>
                        <div className="expanded-info">
                            <p>{extraDetails}</p>
                        </div>
                        <div className="icon-container">
                            {detailsIcons.map((detail, index) => (
                                <div key={index} className="icon-inner-wrapper">
                                    <img className="icon" src={detail} alt="Details icon" />
                                    <p className="icon-description">{iconDescriptions[index]}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </GlueBaseSection>
    );
};

export default BaseCard;
