import { FC, useEffect, useState } from "react"
import { insightCards } from "../../../model/insights-cards"
import GlueBaseSection from "../../common-components/layouts/base-section"
import { InsightCarousel } from "../../common-components/carousels/mobile-carousels/insight-carousel"
import { fetchInsights } from "../../backend-services/insightsService"

export const LatestInsightSection:FC = () => {
    const [insights, setInsights] = useState(insightCards.insights)
    useEffect(()=>{
        fetchInsights(6, 0, true, false).then((data) => setInsights(data.insights))
    },[])
    return(
        <GlueBaseSection>
            <h1 style={{margin: "5rem 0rem"}}> Our Latest Insights </h1>
            <InsightCarousel insightCards={insights}/>
        </GlueBaseSection>
    )
}