import { FC, useContext } from "react"
import styled from "styled-components"
import { GlueGradientButton } from "../../buttons/button"
import './case-study-card.scss'
import { GlueStateContext } from "../../../../utils/context/glue-context"

type CaseStudyProps = {
    backgroundImage: string,
    clientLogo: string,
    clientTitle: string,
    studyArea: string,
    description: string,
    isStudyAvailable: boolean,
    targetLink?: string

}

interface TransientStyleCardProps {
    $backgroundImage: string
}

const StyledCardContentContainer = styled.div.attrs({className: "case-study-card-wrapper"})<TransientStyleCardProps>`
        width: 90%;
        background: url(${p => p.$backgroundImage}),linear-gradient(rgba(255,255,255,0.9),rgba(0,0,0,0.7));
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 1rem;
        margin-bottom: 1rem;
        
`

const CaseStudyCard: FC<CaseStudyProps> = ({backgroundImage, clientLogo, clientTitle, studyArea, description, isStudyAvailable, targetLink}) => {
    const {glueState} = useContext(GlueStateContext)
    const openForm = () => {
        glueState.formTitle = "Contact us for more"; 
        glueState.setOpenContact!(true)
    }

    return(
    <>
        <div className='ourwork-card-container'>
            <StyledCardContentContainer $backgroundImage={backgroundImage}>
                <div className='card-logo'><img alt="study-logo" src={clientLogo}/></div>
                <div className='ourwork-card-content'>
                    <div className="content">
                        <div className="client-title"><p>{clientTitle}</p>
                        <h2>{studyArea}</h2>
                        
                        <div className='hovered-description'> 
                            <p>{description}</p>
                            {isStudyAvailable && <GlueGradientButton text="View Case Study" targetUrl={targetLink} isTargetExternal={true} setTextWhite={false}/>}
                            {!isStudyAvailable && <GlueGradientButton text="Contact us for more" isTargetExternal={false} setTextWhite={false} onClickEvent={openForm}/>}
                        </div>
                        </div>
                    </div>
                </div>
            </StyledCardContentContainer>
        </div>
    </>)
}

export default CaseStudyCard