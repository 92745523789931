import React from 'react';
import parse from 'html-react-parser';
import plusIcon from '../../../assets/people-page/diversity/plus.svg';
import minusIcon from '../../../assets/people-page/diversity/minus.svg';

interface DiversityBoxProps {
  box: string;
  isExpanded: boolean;
  handleBoxClick: (box: string) => void;
}

const DiversityBox: React.FC<DiversityBoxProps> = ({
  box,
  isExpanded,
  handleBoxClick
}) => {

  const getTitle = () => {
    switch (box) {
      case 'global':
        return 'Thinking & <br/> Working Globally';
      case 'opendoor':
        return 'Open Door <br/> Mentality';
      case 'inclusion':
        return 'Social & Work <br/> Inclusion';
      default:
        return '';
    }
  };

  const getContent = () => {
    switch (box) {
      case 'global':
        return (
          <p>
            Reply unites thousands of employees across 16 different countries from all over the world. <br/><br/>
            We consider our diverse range of experiences, life stories, ideas, and points of view as true assets.
          </p>
        );
      case 'opendoor':
        return (
          <p>
            Flat hierarchies, open doors, and open communication are important to us. <br/><br/>
            We foster trust and encourage all to contribute ideas and spark discussions.
          </p>
        );
      case 'inclusion':
        return (
          <p>
            We promote inclusion in both social and employment spheres. <br/><br/>
            Having heterogeneous teams, with people of every gender, age, culture, geographical origin, training and experience is a treasure.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={`diversity-box ${isExpanded ? 'expanded' : ''}`}
      onClick={() => handleBoxClick(box)}
    >
      <h3>{parse(getTitle())}</h3>
      {isExpanded ? (
        <>
          <div className="diversity-content">
            {getContent()}
          </div>
          <img src={minusIcon} className="diversity-minus" alt="Show less" />
          <p className='show-less'>Show less</p>
        </>
      ) : (
        <>
          <img src={plusIcon} className="diversity-plus" alt="Show more" />
          <p className='show-more'>Show more</p>
        </>
      )}
    </div>
  );
};

export default DiversityBox;
