import {FC, useEffect} from 'react'
import GluePage from '../components/glue-page/page'
import { GluePages } from '../model/glue-pages'
import './home.scss'

import WhoWeAre from '../components/home-page/whoweare-section'
import { CapabilitiesSection } from '../components/home-page/capabilities-section'
import OurWorkHome from '../components/home-page/our-work-section'
import InsightsHome from '../components/home-page/insights-section'
import OurClientsHome from '../components/home-page/our-clients-section'
import { ReplyGPTSection } from '../components/home-page/reply-gpt-section'
import ContactUsSection from '../components/home-page/contact-us-section'
import { sendPageEvent } from '../utils/analyticsRequest'
import { useCookies } from 'react-cookie'


export const HomePage: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("/home", "homepage", cookies)
    });
    
    return(
        <GluePage headerLocation={GluePages.HOMEPAGE} >
            <WhoWeAre/>
            <CapabilitiesSection/>
            <OurWorkHome/>
            <InsightsHome/>
            <OurClientsHome/>
            <ReplyGPTSection/>
            <ContactUsSection/>
        </GluePage>
    )
}

