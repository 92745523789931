import React from 'react';

const Gradients = () => (
  <svg style={{height: 0, width: 0, position: 'absolute'}}>
    <defs>
        <linearGradient id="Gradient1">
            <stop offset="0%" stopColor="rgba(0, 131, 149, 0.6)"/>
            <stop offset="100%" stopColor="rgba(0, 41, 47, 0.6)"/>
        </linearGradient>
      {/* Define more gradients here... */}
    </defs>
  </svg>
);

export default Gradients;