import { FC, useContext, useEffect, useState } from "react";
import { Burger } from "./burger/menu-burger";
import React from "react";
import MobileTabletMenu from "./menu/mobile-tablet-menu";
import './mobile-tablet-nav.scss'
import { NavLink } from "react-router-dom";
import { GlueState, GlueStateContext } from "../../../utils/context/glue-context";
import GlueLogoLight from './../../../assets/logos/glue-logo-light.png'


const resetScrollRefToNavbarAndScrollToTop = (glueState: GlueState) => {
    glueState.elementToScroll = 'navbar'
    window.scrollTo(0,0)
}

export const MobileTabletNav: FC = () => {
    const [open, setOpen] = React.useState(false);
    const [hasScrolled, setHasScrolled] = useState(false);
    const {glueState} = useContext(GlueStateContext)


    useEffect(() => {
        const mainContentPosition = document.getElementById('gluepage-main-content')?.offsetTop
        window.addEventListener("scroll", () => {
            setHasScrolled(window.scrollY > mainContentPosition! - 50);
        });
    });

    return(
        <div className={hasScrolled || open ? 'mobile-navigation-black' : 'mobile-navigation-transparent'} id="navbar">
            <div className="mobile-nav-content">
                <NavLink to="/"><img onClick={()=>{resetScrollRefToNavbarAndScrollToTop(glueState)}} className='glue-logo' src={GlueLogoLight} alt="Company Logo" /></NavLink>
                <Burger open={open} setOpen={setOpen}/>
            </div>
                <MobileTabletMenu open={open}/>
            
        </div>
    )
}