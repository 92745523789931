import { Dispatch, FC, SetStateAction, useState, useEffect, useCallback } from 'react';
import './insights-cards-section.scss';
import GlueBaseSection from '../common-components/layouts/base-section';
import InsightCard from '../common-components/content-cards/insights-cards/insight-card';
import Bin from './../../assets/icons/bin.png';
import { ContentType, ServicesOptions } from '../../model/filter-options';
import { Pagination, Select } from 'antd';
import { GlueGradientButton } from '../common-components/buttons/button';
import { fetchInsights } from '../backend-services/insightsService'; // Import the fetchInsights function
import { isMobile } from '../../utils/determine-mobile-tablet';
import useWindowDimensions from '../../utils/window-dimensions';
import { InsightsType } from '../../model/insights'; // Import InsightsType if it's defined in your model
import { MobileFilteredInsights } from './mobile-insights-section/mobile-filtered-insights';
import { CategoryInsightSection } from './mobile-insights-section/insights-by-category';
import { LatestInsightSection } from './mobile-insights-section/latest-insights';

const FilterOptions = {
  Services: [ServicesOptions.Strategy, ServicesOptions.ProductDesign, ServicesOptions.IntegrationAutomation, ServicesOptions.DataDelivery],
  Content: [ContentType.WhitePaper, ContentType.Article, ContentType.BestPractices, ContentType.Video, ContentType.Brochure]
};

interface ServiceDropDownProps {
  setOption: Dispatch<SetStateAction<ServicesOptions>>;
  value: ServicesOptions;
}

interface ContentDropDownProps {
  setOption: Dispatch<SetStateAction<ContentType>>;
  value: ContentType;
}

const ServiceFilterDropdown: FC<ServiceDropDownProps> = ({ setOption, value }) => (
  <Select
    defaultValue={ServicesOptions.NoSelection}
    options={FilterOptions.Services.map((service) => ({ label: service, value: service }))}
    onChange={(option) => { setOption(option); }}
    value={value}
  />
);

const ContentTypeDropdown: FC<ContentDropDownProps> = ({ setOption, value }) => (
  <Select
    defaultValue={ContentType.NoSelection}
    options={FilterOptions.Content.map((content) => ({ label: content, value: content }))}
    onChange={(option) => { setOption(option); }}
    value={value}
  />
);

const FilteredInsights: FC = () => {
  const [serviceOption, setServiceOption] = useState<ServicesOptions>(ServicesOptions.NoSelection);
  const [contentOption, setContentOption] = useState<ContentType>(ContentType.NoSelection);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredInsights, setFilteredInsights] = useState<InsightsType[]>([]);
  const [initialLoadRequired, setInitialLoadRequired] = useState(true)
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationValue, setPaginationValue] = useState(0)
  const [current, setCurrent] = useState(1)

  // Fetch insights based on filters or show all
  const requestInsights = useCallback(async (page: number) => {
    setLoading(true);
    setCurrent(page);
    const requestOffset = 9 * (page-1)
    try {
      let insights = await fetchInsights(9, requestOffset); // Fetch default limit
      // );
      setFilteredInsights(insights.insights);
      setPaginationValue(insights.totalCount)
    } catch (error) {
      console.error("Error fetching filtered insights:", error);
      setFilteredInsights([]);
    } finally {
      setLoading(false);
    }
  },[]);

  const requestFilteredInsights = async (page: number) => {
    setLoading(true);
    setCurrent(page)
    const requestOffset = 9 * (page-1)
    try {
      const applyServiceFilter = serviceOption === ServicesOptions.NoSelection ? undefined : serviceOption
      const applyContentFilter = contentOption === ContentType.NoSelection ? undefined : contentOption
      const applySearchFilter = searchInput === '' ? undefined : searchInput
      await fetchInsights(9, requestOffset, true, false, false, applyContentFilter, applyServiceFilter, applySearchFilter)
      .then((data) => {setFilteredInsights(data.insights); setPaginationValue(data.totalCount)})// Fetch default limit

    } catch (error) {
      console.error("Error fetching filtered insights:", error);
      setFilteredInsights([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(initialLoadRequired){
      requestInsights(1)
      setInitialLoadRequired(false)
    }
  }, [initialLoadRequired, requestInsights]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  

  const resetInsights = () => {
    requestInsights(1);
    setServiceOption(ServicesOptions.NoSelection);
    setContentOption(ContentType.NoSelection);
    setSearchInput('');
  };

  return (
    <>
      <GlueBaseSection>
        <h1 className='insight-title'>Explore All Insights</h1>
        <div className='filters-wrapper'>
          <div className='form-fields-container'>
            <ServiceFilterDropdown setOption={setServiceOption} value={serviceOption} />
            <ContentTypeDropdown setOption={setContentOption} value={contentOption} />
            <input className='search-input' placeholder='Search' value={searchInput} onChange={handleSearchInputChange} />
            <GlueGradientButton text={"Apply"} targetUrl={''} isTargetExternal={false} setTextWhite={true} onClickEvent={requestFilteredInsights} />
          </div>

          <div className='clear-filter-container'>
            <button className='reset-button' onClick={() => { resetInsights(); }}><img src={Bin} alt="clear filters" />
              &nbsp; &nbsp;Clear all filters
            </button>
          </div>
        </div>
      </GlueBaseSection>
      
      <GlueBaseSection>
        {loading && <div>loading...</div>}
        {!loading && filteredInsights.length > 0 && <div className='insights-cards-container'>
          {filteredInsights.map((insight, key) =>
            <InsightCard key={key} image={insight.image} borderAndTextColor={insight.borderAndText} cornerText={insight.insightType} linkText={insight.linkText} linkTarget={insight.linkTarget} />
          )}
        </div>}
        {filteredInsights.length === 0 && <div><p>No Insights found with provided filters</p></div>}
        {paginationValue > 9 && <div className='pagination-container'>
          <Pagination pageSize={9} showSizeChanger={false} align='center' current={current} onChange={(page) => {requestFilteredInsights(page)}} total={paginationValue}/>
        </div>}
        
      </GlueBaseSection>
      
    </>
  );
};

const MobileInsightSections: FC = () => (
  <>
    <LatestInsightSection />
    <CategoryInsightSection />
    <MobileFilteredInsights />
  </>
);

export const InsightsCardsSection: FC = () => {
  const isMobileMode = isMobile(useWindowDimensions().width);

  return (
    <>
      {!isMobileMode && <FilteredInsights />}
      {isMobileMode && <MobileInsightSections />}
    </>
  );
};
