import Slider, { Settings } from "react-slick";
import { FC } from 'react'
import './our-work-carousel.scss'
import useWindowDimensions from "../../../utils/window-dimensions";
import { isMobile } from "../../../utils/determine-mobile-tablet";
import { CaseStudies, CaseStudy } from "../../../model/case-studies";
import  CaseStudyCard  from "../content-cards/case-study-cards/case-study-card";


interface CarouselProps {
    caseStudies?: CaseStudy[]
    sliceRequiredForHome?: boolean
}


const OurWorkCarousel:FC<CarouselProps> = ({caseStudies = CaseStudies, sliceRequiredForHome}) => {
    const isMobileMode = isMobile(useWindowDimensions().width)

    var carouselSettings: Settings = {
        dots: isMobileMode ? false : true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: isMobileMode ? 1 : 3,
        arrows: isMobileMode ? false : true
      };

    return (
        <div className='carousel-outer-wrapper'>
            <div className='carousel-container'>
                <Slider {...carouselSettings}>
                {caseStudies.map((caseStudy, index) => <div className='carousel-slide-container' key={index}>
                        <CaseStudyCard backgroundImage={caseStudy.image} clientLogo={caseStudy.clientLogo} clientTitle={caseStudy.clientTitle} studyArea={caseStudy.studyArea} description={caseStudy.description} targetLink={caseStudy.targetLink} isStudyAvailable={caseStudy.isStudyAvailable}/>
                    </div>)}    
                    
                </Slider>
            </div>
        </div>
  )
}

export default OurWorkCarousel