import React from 'react';
import './headshot-cards-section.scss';
// import Slider from 'react-slick';
// import useWindowDimensions from "../../utils/window-dimensions";
// import { isMobile } from "../../utils/determine-mobile-tablet";
import GouthamImage from '../../assets/headshots/Goutham.webp';
import CraigImage from '../../assets/headshots/Craig.webp';
import AshishImage from '../../assets/headshots/Ashish.webp';
import RodgerImage from '../../assets/headshots/Rodger.webp';
import PramodImage from '../../assets/headshots/Pramod.webp';
import ShrenikaImage from '../../assets/headshots/Shrenika.webp';
import NischImage from '../../assets/headshots/Nisch.webp';




interface HeadshotItemProps {
    imageURL: string;
    name: string;
    position: string;
  }

  const HeadshotItem: React.FC<HeadshotItemProps> = ({ imageURL, name, position }) => (
    <article className="headshot-wrapper">
      <div className="image-wrapper">
        <img src={imageURL} alt={`${name}'s headshot`} className="headshot-image" />
      </div>
      <h3 className="headshot-name">{name}</h3>
      <p className="headshot-position">{position}</p>
    </article>
  );

  const headshotsData: HeadshotItemProps[] = [
    { 
        imageURL: GouthamImage, 
        name: "Goutham Shrikrishna", 
        position: "Partner" 
    },
    { 
        imageURL: NischImage, 
        name: "Nisch Kanahia", 
        position: "Integration Lead" 
    },
    { 
        imageURL: ShrenikaImage, 
        name: "Shrenika Sanghvi", 
        position: "Integration Lead" 
    },
    { 
        imageURL: AshishImage, 
        name: "Ashish Kumar", 
        position: "Integration Lead" 
    },
    { 
        imageURL: PramodImage, 
        name: "Pramod Nagaraj", 
        position: "Delivery Lead" 
    },
    { 
        imageURL: CraigImage, 
        name: "Craig Murdoch", 
        position: "Transformation Lead" 
    },
    { 
        imageURL: RodgerImage, 
        name: "Rodger Hayes", 
        position: "Delivery Lead" 
    }


      
  ];
  
  
  export const HeadshotSection: React.FC = () => {

    const firstRowData = headshotsData.slice(0, 4);
    const secondRowData = headshotsData.slice(4);

    // const isMobileMode = isMobile(useWindowDimensions().width)



    // const carouselSettings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false
    // };


    return (
        <div className="headshot-section">
            <br/>
            <br/>
            <br/>
            <h1 className="headshots-title">MEET THE TEAM</h1>
            <br/>
            <br/>
            <br/>


            {/* 
            {isMobileMode ? (
                // Render the carousel for mobile view
                <div className="headshots-carousel">
                    <Slider {...carouselSettings}>
                        {headshotsData.map((headshot, index) => (
                            <div key={index} className="carousel-slide-container">
                                <HeadshotItem
                                    imageURL={headshot.imageURL}
                                    name={headshot.name}
                                    position={headshot.position}
                                />
                                <br/>
                                <br/>
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : ( */}


            {/* First Row */}
            <div className="headshots-grid first-row">
                {firstRowData.map((headshot, index) => (
                    <HeadshotItem
                        key={`headshot-${index}`}
                        imageURL={headshot.imageURL}
                        name={headshot.name}
                        position={headshot.position}
                    />
                ))}
            </div>
            <br/>
            <br/>
            <br/>

            {/* Second Row */}
            <div className="headshots-grid second-row">
                {secondRowData.map((headshot, index) => (
                    <HeadshotItem
                        key={`headshot-second-${index}`}
                        imageURL={headshot.imageURL}
                        name={headshot.name}
                        position={headshot.position}
                    />
                ))}
                <br/>
                <br/>
            </div>
        </div>
    );
};










  
