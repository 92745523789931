import {FC, useEffect} from 'react'
import { GluePages } from '../../model/glue-pages'
import GluePage from '../../components/glue-page/page'
//import { OurPartnersSection } from '../../components/our-work-page/our-partners-section'
import { GlueCompanySection } from '../../components/company-page/company-map-section'
//import { OurHistorySection } from '../../components/company-page/our-history-section'
import { HeadshotSection } from '../../components/company-page/headshot-cards-section'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../../utils/analyticsRequest'

export const GlueCompany: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-company", "AboutUs", cookies)    
      });
    return(
        <GluePage headerLocation={GluePages.COMPANY}>
            {/* <OurHistorySection/>
            <OurPartnersSection/> */}
            <HeadshotSection/>
            <GlueCompanySection/>
        </GluePage>
    )
}