export enum CommonPageSectionsId{
    NAVBAR = "navbar",
    MAINCONTENT = "main-content-container",
    FOOTER = "footer"
}

export enum ServicesSectionId{
    Strategy = "strategy-and-architecture-section",
    Produdct = "product-transformation-section",
    Integration = "integration-automation-section",
    Data = "data-strategy-section"
}

export enum PeopleSectionId{
    Culture = "culture-section",
    ContiniousLearning = "continiousl-learning-section",
    Diversity = "diversity-section",
    LifeAtGlue = "glue-life-section"
}

export enum EnvironmentSectionId{
    WeCare = "wecare-section",
    NetZero = "netzero-section",
    Sustainability = "insights-section"
}

export enum CompanySectionId{
    AboutGlue = "about-glue-section",
    AboutReply = "about-reply-section",
    Offices = "office-section"
}

export enum OurWorkSectionId{
    CaseStudies = 'case-study-section'
}