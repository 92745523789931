import { FunctionComponent } from "react";
import "./description-comp.scss";

const Description: FunctionComponent = () => {
  return (
    <div className="root">
      <section className="we-help-organisations-container">
        <span className="we-help-organisations-container1">
          <p className="we-help-organisations">
            We help organisations to transform their digital experiences through <br />
            Strategy & Architecture, Product Transformation, Integration & Automation and Data Delivery.
          </p>
          <p className="blank-line">&nbsp;</p>
          <p className="if-you-are">
            If you are ready to contribute to our innovation, we would love to
            hear from you! 
          </p>
        </span>
      </section>
    </div>
  );
};

export default Description;
