import { FunctionComponent, useState, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import "./event-and-social.scss";
import useWindowDimensions from "../../../utils/window-dimensions";
import { isMobile } from "../../../utils/determine-mobile-tablet";
import { fetchSocialEvents } from "../../backend-services/socialeventsService";

export type EventAndSocialType = {
  className?: string;
};

const EventAndSocial: FunctionComponent<EventAndSocialType> = ({ className = "eventAndSocial" }) => {
  const [socialEvents, setSocialEvents] = useState<any[]>([]); // State to hold fetched social events
  const [loading, setLoading] = useState(true);
  const isMobileMode = isMobile(useWindowDimensions().width)

  var settings: Settings = {
    infinite: true,
    speed: 500,
    centerMode: false,
    slidesToShow: 1,  // Show only 1 image at a time
    slidesToScroll: 1, // Scroll one image at a time
    arrows: !isMobileMode, // Show arrows only on desktop
    autoplay: true,  // Optionally enable autoplay if needed
    autoplaySpeed: 5000 // Adjust the autoplay speed if autoplay is enabled
  };

  // Fetch social events on component mount
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const maxShownEvents = 100
        const events = await fetchSocialEvents(maxShownEvents);  // Fetch 9 events (if you need 9 initially)
        setSocialEvents(events);  // Set the fetched events into state
        setLoading(false); // Stop loading once events are fetched
      } catch (error) {
        console.error("Error fetching social events:", error);
        setLoading(false);  // Stop loading on error too
      }
    };
    fetchEvents();
  }, []);



  if (loading) {
    return <div>Loading events...</div>;  // Simple loading indicator
  }

  if (socialEvents.length === 0) {
    return <div>No events available.</div>;  // Handle empty state
  }

  return (
    <div className={`root ${className}`}>
      <header className="events-socials-wrapper">
        <div className="events-socials">
          <h1>Events & Socials</h1>
        </div>
      </header>
      <section className="image-section">
        <div className="image-container">


            <Slider {...settings}>
              {socialEvents.map((event, index) => (
                <div className="image-container" key={index}>
                  <img
                    src={event.image || ''}
                    alt={event.socialDescription || "Events & Socials"}
                    className="event-image"
                  />
                </div>
              ))}
            </Slider>

        </div>


      </section>
      <br/>
      <br/>
    </div>
  );
};

export default EventAndSocial;