import { FC } from "react";
import Slider, { Settings } from "react-slick";
import InsightCard from "../../content-cards/insights-cards/insight-card";
import './insight-carousel.scss'
import { InsightsType } from "../../../../model/insights";


type CarouselProps = {
    insightCards: InsightsType[]
}

export const InsightCarousel: FC<CarouselProps> = ({insightCards}) => {
    

    var carouselSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        arrows: false
      };


    return(
        <div className="insight-carousel-wrapper">
            <div className="insight-slide-container">
                <Slider {...carouselSettings}>
                    {insightCards.map((content, index) => (
                        <InsightCard key={index+'insight-slide'} image={content.image} borderAndTextColor={content.borderAndText} cornerText={content.insightType} linkText={content.linkText} linkTarget={content.linkTarget}/>
                    ))}
                </Slider>
            </div>
        </div>
    )
}