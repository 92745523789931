import { FC } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './company-map-section.scss'
import { CompanySectionId } from "../../model/section-ids"

export const GlueCompanySection: FC = () => {
    return(
        <>
            <GlueBaseSection id={CompanySectionId.Offices}>
                <h1 className="office-title"> OUR OFFICES </h1>
                <div className='glue-company-wrapper'>
                    <div className="address-container">
                        <h2>Glue Reply</h2>
                        <h3>Corporate Headquarters</h3>
                        <p>
                            38 Grosvenor Gardens
                        </p>
                        <p>
                            London SW1W 0EB - UK
                        </p>
                        <p>
                            phone: +44 (0)20 7730 6000
                        </p>

                        <h3>London Office</h3>
                        <p>
                            160 Victoria Street 2nd floor Nova South
                        </p>
                        <p>
                            Westminster London SW1E 5LB - UK
                        </p>
                    </div>
                    <div className="map-container"><iframe title="glue-reply-map" width="100%" height="500px" src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=38%20Grosvenor%20Gardens,%20London,%20SW1W%200EB+(Glue%20Reply)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>
                </div>
            </GlueBaseSection>
        </>

    )
}