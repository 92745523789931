import { FC } from "react";
import "./insight-card.scss"
import styled from "styled-components";
import DummyImageForSizing from "./../../../../assets/insights/insight-dummy-image.webp"


type InsightCardProps = {
    image:string;
    borderAndTextColor: string;
    cornerText: string;
    linkText: string;
    linkTarget: string;
}

interface TransientStyledCornerProps {
    $borderAndTextColor: string;
}

interface TransientStyledImageProps {
    $backgroundImage: string;
}

const StyledCornerContainer = styled.div.attrs(({className:'insight-corner'}))<TransientStyledCornerProps>`
    position: absolute;
    height: 10%;
    width:55%;
    bottom:95%;
    font-family:"AlaskaExpandedRegular";
    border: 2px solid ${p => p.$borderAndTextColor};
    border-radius: 10px;
    display: flex;
    background-color: #f5f5f5;
    color: ${p => p.$borderAndTextColor};
    align-items: center;
    text-align: center;
    a{
        color: ${p => p.$borderAndTextColor};
    }
`

const StyledImageContainer = styled.div.attrs(({className:'insight-image-container'}))<TransientStyledImageProps>`
    width: 100%;
    position:relative;
    background:url(${p => p.$backgroundImage}),linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3));
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 50%;
    cursor:pointer;
    border-radius: 2rem;
    animation: overlayin;
    animation-duration: 0.5s;
    margin:2%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    &:hover{
        background-size:  cover;
        transform: scale(1.04);
        animation: overlayout;
        animation-duration: 0.5s;
    }
        

`

const InsightCard: FC<InsightCardProps> = ({borderAndTextColor, cornerText, linkText, linkTarget, image}) => {

    return (
        <StyledImageContainer $backgroundImage={image}>
            <a href={linkTarget}>
                <div className="insights-content">
                    <img src={DummyImageForSizing} className="insight-image" alt={"insights"}/>
                    <div className="link-container"><p className="insight-link">{linkText}</p></div>
                    <div className="insight-corner-container">
                        <StyledCornerContainer $borderAndTextColor={borderAndTextColor}>
                            <ul>
                                <li>
                                    <p>{cornerText}</p>
                                </li>
                            </ul>

                        </StyledCornerContainer>
                    </div>
                </div>
                
            </a>
        </StyledImageContainer>

    );
};

export default InsightCard;