import { FC, useEffect, useState } from "react";
import GlueBaseSection from "../common-components/layouts/base-section";
import { GlueGradientButton } from "../common-components/buttons/button";
import './insights-section.scss';
import InsightCard from "../common-components/content-cards/insights-cards/insight-card";
import { CommonPageSectionsId } from "../../model/section-ids";
import { isMobile } from "../../utils/determine-mobile-tablet";
import useWindowDimensions from "../../utils/window-dimensions";
import { InsightCarousel } from "../common-components/carousels/mobile-carousels/insight-carousel";
import { insightCards } from "../../model/insights-cards";
import { InsightsType } from "../../model/insights";
import { fetchInsights } from "../backend-services/insightsService";

// Static fallback data
const staticInsights = insightCards.insights.slice(0, 3);
//console.log("Local strapi used?" + process.env.REACT_APP_USE_LOCAL_STRAPI)
//console.log(process.env.REACT_APP_PRODUCTION_STRAPI)
  const DesktopInsights: FC<{ insights: InsightsType[] }> = ({ insights }) => (
  <div className="insights-cards-wrapper">
    {insights.map((insight, key) => (
      <InsightCard
        key={"insight-" + key}
        image={insight.image}
        borderAndTextColor={insight.borderAndText}
        cornerText={insight.insightType}
        linkText={insight.linkText}
        linkTarget={insight.linkTarget}
      />
    ))}
  </div>
);

const InsightsHome: FC = () => {
  const [homeInsights, setHomeInsights] = useState<InsightsType[]>(staticInsights);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Exclude sustainable insights
        const fetchedInsights = await fetchInsights(3,0,true,false, true); // Fetch 3 insights
        setHomeInsights(fetchedInsights.insights);
      } catch (err) {
        console.error("Error fetching insights:", err);
        // staticInsights is already set as default state
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const isMobileMode = isMobile(useWindowDimensions().width);
  // Reaplce next like with the Glue Reply logo and three dots
  // if (loading) return <div>Loading...</div>;

  return (
    <div className="insights-section">
      <GlueBaseSection>
        <h1>OUR LATEST INSIGHTS</h1>
        {loading && <div>loading...</div>}
        {!isMobileMode && !loading ? <DesktopInsights insights={homeInsights} /> : <InsightCarousel insightCards={homeInsights} />}
        <GlueGradientButton
          text="Explore all insights"
          targetUrl="/glue-insights"
          scrollToTargetContainer={CommonPageSectionsId.MAINCONTENT}
          isTargetExternal={false}
          setTextWhite={false}
        />
      </GlueBaseSection>
    </div>
  );
};

export default InsightsHome;
