import { EnvironmentSectionId } from "../../model/section-ids";
import GlueBaseSection from "../common-components/layouts/base-section";
import '../environments-page/sustainability-net-zero.scss'

export const SustainabilityNetZero: React.FC = () => {
    return (
        <GlueBaseSection id={EnvironmentSectionId.NetZero}>
            <div className="content-wrapper-sustainability">
                <div className="flex-container">
                    <h1 className="sustainability-title">
                        SUSTAINABILITY & NET ZERO
                    </h1>
                    <h2 className="sustainability-description">
                        The Reply Network have outlined a roadmap to take environmental action, aiming to reach Carbon neutrality by 2025, and have Net-Zero GHG emissions by 2030.
                    </h2>
                    <p className="sustainability-detail-text">
                        To achieve this goal, Reply is working on an emissions reduction programme, which includes actions to optimise the company’s energy consumption. Our commitment to this is initiative is reflected through our electric vehicle scheme, a switch to renewable energy, and active monitoring of our impact.
                    </p>
                    <p className="sustainability-detail-text">
                        Adopting an environment focused approach in our work is a strategic decision, so we make a conscious effort to promote sustainability themes across our practices. We encourage innovative ideas for our internal sustainability projects, communicated through our blog posts and whitepapers.
                    </p>
                </div>
            </div>

        </GlueBaseSection>
    )
}

export default SustainabilityNetZero;