import {FC, useContext} from 'react'
import GlueBaseSection from '../common-components/layouts/base-section'
import './contact-us-section.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faEnvelope, faPenSquare, faUserGroup, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { GlueStateContext } from '../../utils/context/glue-context';


type ContactButtonProps = {
    text: string;
    icon: IconDefinition;
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const FontContactButton: FC<ContactButtonProps> = ({text, icon, onClick}) => {
    
    return(
        <div className='contact-button-container' onClick={(e)=> onClick ? onClick(e) : {}}>
            <FontAwesomeIcon className='contact-svg-icon' icon={icon} />
            <div className='contact-text-container'>
                <p>{text}</p>
                <FontAwesomeIcon className='contact-chevron' icon={faChevronRight}/>
            </div>
        </div>
    )
}



const ContactUsSection:FC = () => {
    const navigate = useNavigate()
    function navigateToCareers (){
        window.scrollTo(0,0)
        navigate('/glue-careers')
    }

    const {glueState} = useContext(GlueStateContext)
    
    return(
        <>
            <GlueBaseSection>
                <h1>CONTACT US</h1>
                <div className='contact-button-wrapper'>
                    <FontContactButton text='Contact Us' icon={faEnvelope} onClick={()=>{glueState.formTitle = "Contact Us";glueState.setOpenContact!(true)}}/>
                    <FontContactButton text='Join Us' icon={faUserGroup} onClick={()=>navigateToCareers()}/>
                    <FontContactButton text='Send us an RFP/RFI' icon={faPenSquare} onClick={()=>{glueState.formTitle = "Send us an RFP/RFI";glueState.setOpenContact!(true); glueState.isRFPEmail=(true)}}/>
                </div>
            </GlueBaseSection>
        </>
    )
}

export default ContactUsSection