import { FC, useEffect } from 'react'
import GluePage from '../../components/glue-page/page'
import { GluePages } from '../../model/glue-pages'
import GlueBaseSection from '../../components/common-components/layouts/base-section'
import WeCare from '../../components/environments-page/we-care'
import SustainabilityNetZero from '../../components/environments-page/sustainability-net-zero'
import SustainableInsights from '../../components/environments-page/sustainable-insights'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../../utils/analyticsRequest'

export const GlueEnvironment: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-environment", "Environment", cookies)    
      });
    return (
        <GluePage headerLocation={GluePages.ENVIRONMENT}>
            <GlueBaseSection>
                <WeCare />
                <SustainabilityNetZero />
                <SustainableInsights />
            </GlueBaseSection>

        </GluePage>
    )
}