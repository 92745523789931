let baseURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : process.env.REACT_APP_PRODUCTION_STRAPI
let imageURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : '';


export const fetchSocialEvents = async (limit = 100) => {
    const excludeNoImageEntries = 'filters[eventImage][$notNull]=true'
    const getFirstNelements = `pagination[start]=0&pagination[limit]=${limit}`
    const sortCreatedAtDescending = 'sort[0]=createdAt:desc'
    const endpoint = `${baseURL}/api/social-events?populate=*&${excludeNoImageEntries}&${getFirstNelements}&${sortCreatedAtDescending}`;
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) throw new Error('Failed to fetch social events');
  
      const data = await response.json();
    //   Mapping
    /// console.log(data.data)
      const formattedSocialEvents = data.data.map((item: any) => ({
        image: imageURL + item.attributes.eventImage.data.attributes.url,
        socialDescription:  item.attributes.socialDescription
      }));
      // console.log("URL" + formattedSocialEvents[0].eventImage)
      return formattedSocialEvents;
    } catch (error) {
      console.error("Error fetching social events:", error);
      throw error;
    }
  };