export enum ServicesOptions {
    NoSelection = "Services",
    Strategy = "Strategy & Architecture",
    ProductDesign = "Product Transformation",
    IntegrationAutomation = "Integration & Automation",
    DataDelivery = "Data Delivery"
}

export enum ContentType {
    NoSelection = "Content Type",
    WhitePaper = "White Paper",
    Article = "Article",
    BestPractices = "Best Practices",
    Video = "Video",
    Brochure = "Brochure"
}