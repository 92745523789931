import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { NavLink} from 'react-router-dom'
import GlueLogoLight from './../../assets/logos/glue-logo-light.png'
import GlueLogoDark from './../../assets/logos/glue-logo-dark.png'
import { AboutDropdownMenu } from './dropdown-menus/about-us/about-us-submenu';
import { GlueState, GlueStateContext } from '../../utils/context/glue-context';
import useWindowDimensions from '../../utils/window-dimensions';
import './navbar.scss'
import { MobileTabletNav } from './mobile-tablet-nav/mobile-tablet-nav';
import {isMobileOrTablet } from '../../utils/determine-mobile-tablet';

const Items = [
    
    {
        label: (<NavLink to="/glue-services" className='nav-item-link'>Services</NavLink>),
        key:'services'
    },
    {
        label: (<NavLink to="/glue-our-work" className='nav-item-link'>Our Work</NavLink>),
        key:'ourwork'
    },
    {
        label: (<NavLink to="/glue-insights" className='nav-item-link'>Insights</NavLink>),
        key:'insights'
    },
    {
        label: (<NavLink to="/glue-careers" className='nav-item-link'>Careers</NavLink>),
        key:'careers'
    }
]

type NavItemProps = {
  link: JSX.Element,
  isDropDownActive: boolean,
  glueState: GlueState,
  
 
};

type NavDropdownProps = {
    buttonText: string,
    isActive: boolean,
    isLightRequired:boolean,
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>
}

type ContactButtonProps = {
  text: string;
  iconSrc: string;
  setOpenContact: Dispatch<SetStateAction<boolean>>
};

const resetScrollRefToNavbarAndScrollToTop = (glueState: GlueState) => {
    glueState.elementToScroll = 'navbar'
    window.scrollTo(0,0)
}

const NavItem: FC<NavItemProps> = ({ link, isDropDownActive, glueState }) => (
  <li className={isDropDownActive?"nav-item-dd-active":"nav-item"} onClick={()=>{resetScrollRefToNavbarAndScrollToTop(glueState)}}>{link}</li>
);

const NavDropdown: FC<NavDropdownProps> = ({buttonText, isActive, isLightRequired, setIsActive}) => (
    <li className="nav-item"><button onClick={()=>{setIsActive(!isActive)}} className={isLightRequired || isActive ? 'dropdown-active-button' : 'dropdown-nav-button'} >{buttonText}</button></li>
);

export const ContactButton: FC<ContactButtonProps> = ({ text, iconSrc}) => {
    const {glueState} = useContext(GlueStateContext)
    
    
    const sendEmail = () => {
        glueState.formTitle = "Contact Us"
        glueState.setOpenContact!(true)
    };
    return(<button className="contact-button" onClick={()=>{sendEmail()}}>
        <span>{text}</span>
        <img src={iconSrc} alt="" className="contact-icon" />
    </button>)
};

type NavbarProps = {
    updateScrollTargetElementOnDrodown?: React.Dispatch<React.SetStateAction<string>>,
    setOpenContact: Dispatch<SetStateAction<boolean>>,
    isContactFormOpen: boolean;
}


const DesktopNavbar: FC<NavbarProps> = ({updateScrollTargetElementOnDrodown, setOpenContact, isContactFormOpen}) => {
    const [dropdownIsActive, setDropDownIsActive] = useState(false)
    const [hasScrolled, setHasScrolled] = useState(false);
    const {glueState} = useContext(GlueStateContext)


    useEffect(() => {
        const mainContentPosition = document.getElementById('gluepage-main-content')?.offsetTop
        if (dropdownIsActive || isContactFormOpen) {
            document.body.style.overflow = 'hidden';
        } 
        else {
            document.body.style.overflow = 'scroll';
        }
        window.addEventListener("scroll", () => {
            setHasScrolled(window.scrollY > mainContentPosition! - 50);
        });
    });

    return (
        <>
            <nav id="navbar" className={!dropdownIsActive ? 'navbar': 'navbar-with-dropdown'} onMouseLeave={() => {setDropDownIsActive(false)}}>
                <div className={dropdownIsActive || hasScrolled ? 'navbar-container-light': 'navbar-container'} >
                    <div className={dropdownIsActive || hasScrolled ? 'navbar-content-light': 'navbar-content'}>
                        <NavLink to="/"><img onClick={()=>{resetScrollRefToNavbarAndScrollToTop(glueState)}} className='glue-logo' src={dropdownIsActive || hasScrolled ? GlueLogoDark : GlueLogoLight} alt="Company Logo" /></NavLink>
                        <div className="main-nav">
                        <ul className="nav-list">
                            {Items.map((item, index) => (
                            <NavItem isDropDownActive={dropdownIsActive || hasScrolled} key={index} link={item.label} glueState={glueState} />
                            ))}
                            <NavDropdown buttonText='About Us' isActive={dropdownIsActive} isLightRequired={hasScrolled} setIsActive={setDropDownIsActive}/>
                        </ul>
                        
                        </div>
                        <div className="contact-wrapper">
                        <ContactButton text="Contact us" iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/f8db7f0046e5e78b47cebe45d7739a5ecb669b6025a0ba3efea61f7481d3f65e?apiKey=4641169c81374835b0d9ae8f2ea89954&" setOpenContact={setOpenContact}/>
                        </div>
                    </div>
                </div>
                {dropdownIsActive && 
                <div className={'nav-submenu'} >
                    <div className='dropdown-content'>
                        {dropdownIsActive && <AboutDropdownMenu updateScrollTargetElementOnDrodown={updateScrollTargetElementOnDrodown!}/>}
                    </div>
                </div>}
            </nav>
            
        </>
    );
};




export const Navbar: FC<NavbarProps> = ({updateScrollTargetElementOnDrodown, setOpenContact, isContactFormOpen}) => {
    const isMobileOrTabletMode = isMobileOrTablet(useWindowDimensions().width)

    return(
        <>
            {!isMobileOrTabletMode && <DesktopNavbar updateScrollTargetElementOnDrodown={updateScrollTargetElementOnDrodown} isContactFormOpen={isContactFormOpen} setOpenContact={setOpenContact}/>}
            {isMobileOrTabletMode && <MobileTabletNav/>}
        </>
    )
}



