import { FC, useCallback, useContext, useEffect, useState } from "react";
import OurWorkCarousel from "../common-components/carousels/our-work-carousel";
import GlueBaseSection from "../common-components/layouts/base-section";
import { GlueGradientButton } from "../common-components/buttons/button";
import './our-work-section.scss';
import { OurWorkSectionId } from "../../model/section-ids";
import { CaseStudies } from "../../model/case-studies";
import { fetchCaseStudies } from "../backend-services/casestudiesService";
import { GlueStateContext } from "../../utils/context/glue-context";

// Static fallback data
const staticCaseStudies = CaseStudies;

const OurWorkHome: FC = () => {

  const [loading, setLoading] = useState(true);

  const {glueState, setGlueState} = useContext(GlueStateContext)

  const fetchData = useCallback(async () => {
    try {
      const CASE_STUDIES_ON_HOMEPAGE = 5
      const fetchedCaseStudies = await fetchCaseStudies(CASE_STUDIES_ON_HOMEPAGE, 0, true);
      glueState.caseStudies = fetchedCaseStudies.caseStudies
      setGlueState(glueState)
    } catch (err) {
      console.error("Error fetching case studies:", err);
      glueState.caseStudies = staticCaseStudies
      setGlueState(glueState)
      // Use static fallback on error without setting an error message
      // staticCaseStudies is already set as default state
    } finally {
      setLoading(false);
    }
  },[glueState ,setGlueState]);

  useEffect(() => {
    

    fetchData();
  }, [fetchData]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="work-section">
      <GlueBaseSection>
        <h1>OUR WORK</h1>
        <OurWorkCarousel sliceRequiredForHome={true} caseStudies={glueState.caseStudies} />
        <GlueGradientButton
          text="See all our work"
          targetUrl="/glue-our-work"
          scrollToTargetContainer={OurWorkSectionId.CaseStudies}
          isTargetExternal={false}
          setTextWhite={false}
        />
      </GlueBaseSection>
    </div>
  );
};

export default OurWorkHome;
