import {FC} from 'react'
import './reply-gpt-section.scss'
import GlueBaseSection from '../common-components/layouts/base-section'
import ReplyLogoLight from '../../assets/logos/reply-white-logo.png'
import GPTLogo from '../../assets/logos/glue-gpt.png'
import { GlueGradientButton } from '../common-components/buttons/button'



const ReplySectionCard:FC = () => {

    return (
        <>
            <div className='reply-section-card-container'>
                <div className='section-card-content'>
                    <img src={ReplyLogoLight} alt="logo"/>

                    <h1>
                            15,000+ experts, 16 countries, & a 25-year heritage
                    </h1>
        
                    <div>
                        <p>We are part of the Reply Network</p>
                        <p>Access unlimited capabilities and diverse expertise within a global network of collaborating companies.</p>
                    
                    </div>
                    <GlueGradientButton text={'Find Out More'} targetUrl={'https://www.reply.com/en'} isTargetExternal={true} setTextWhite={true} />
                  
                </div>
                <br/>
            </div>
        </>
    )
}

const GPTSectionCard:FC = () => {

    return (
        <>
            <div className='gpt-section-card-container'>
                <div className='section-card-content'>
                    <img src={GPTLogo} alt="logo"/>
                    <h1>
                        GlueGPT - Our Latest AI Development
                    </h1>
                    <p>
                    Unlocking speed, quality and innovation with our in-house built AI generator. 
                    </p>
                    <p>Glue GPT serves as a comprehensive resource, designed to streamline and accelerate your daily activities. Trained on over 25 years of data and more than 20,000 integrations, it offers you not just an expert but a robust database accessible anytime through an intuitive chat interface.</p>
                    <GlueGradientButton text={'Find Out More'} targetUrl={'https://www.linkedin.com/posts/glue-reply_the-journey-of-glue-gpt-from-concept-to-activity-7203337778598658049-XLG9?utm_source=share&utm_medium=member_desktop'} isTargetExternal={true} setTextWhite={true} />
                  
                </div>
                <br/>
            </div>
        </>
    )
}

export const ReplyGPTSection: FC = () => {
    return(
        <>
            <GlueBaseSection>
                <div className='reply-gpt-container'>
                    <ReplySectionCard/>
                    <GPTSectionCard/>

                </div>
            </GlueBaseSection>
        </>

    )
}