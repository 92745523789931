import { FC, useContext } from "react";
import StrategyBG from './../../../../assets/home-page/capabilities/strategy-achitecture.jpg'
import ProductTransformationBG from './../../../../assets/home-page/capabilities/product-transformation.png'
import DataManagementBG from './../../../../assets/home-page/capabilities/data-management.png'
import IntegrationBG from './../../../../assets/home-page/capabilities/integration-automation.png'
import { CommonPageSectionsId} from "../../../../model/section-ids";
import Slider, { Settings } from "react-slick";
import { CapabilityCard } from "../../content-cards/capability-card/capability-card";
import './capability-carousel.scss'
import { GlueStateContext } from "../../../../utils/context/glue-context";
import { useNavigate } from "react-router-dom";

const CardContents = [
    {
        title: "Strategy & Architecture",
        imageUrl: StrategyBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Product Transformation",
        imageUrl: ProductTransformationBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Integration & Automation",
        imageUrl: IntegrationBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Data Strategy & Management",
        imageUrl: DataManagementBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    }
]

export const CapabilityCarousel: FC = () => {
    const navigate = useNavigate()
    const {glueState} = useContext(GlueStateContext)
    const scrollToServices = () => {
        glueState.elementToScroll = CommonPageSectionsId.MAINCONTENT
        navigate('/glue-services')
        
    }

    var carouselSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        arrows: false
      };


    return(
        <div className="capability-carousel-wrapper">
            <div className="capability-slide-container">
                <Slider {...carouselSettings}>
                    {CardContents.map((content, index) => (
                        <div className="slide-card" onClick={()=>scrollToServices()}><CapabilityCard title={content.title} backgroundImage={content.imageUrl} targetServiceSectionId={content.containerId} key={"capability-"+index}/></div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}