import { CaseStudiesArray} from "../../model/case-studies";

let baseURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : process.env.REACT_APP_PRODUCTION_STRAPI
let imageURL = process.env.REACT_APP_USE_LOCAL_STRAPI === 'true' ? process.env.REACT_APP_LOCAL_STRAPI : '';

const buildPaginationParams = (limit: number, offset: number) => {
  return `&pagination[start]=${offset}&pagination[limit]=${limit}`;
};
const buildFilters = (homePageOnly: Boolean ) =>{
  return homePageOnly ?  `&filters[isOnHomepage]=true`: '';
}
export const fetchCaseStudies = async (limit = 3, offset = 0, homePageOnly = false) => {
    let endpoint = `${baseURL}/api/casestudies?populate=*&filters[backgroundImage][$notNull]=true&filters[clientLogo][$notNull]=true`;
    endpoint += buildPaginationParams(limit, offset)
    endpoint += buildFilters(homePageOnly)
    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) throw new Error('Failed to fetch case studies');
  
      const data = await response.json();
   
      const formatCaseStudies: CaseStudiesArray = {
        caseStudies: data.data.map((item: any) => ({
          image: imageURL + item.attributes.backgroundImage.data.attributes.url,
          clientLogo: imageURL + item.attributes.clientLogo.data.attributes.url,
          clientTitle: item.attributes.clientTitle,
          studyArea: item.attributes.studyArea,
          description: item.attributes.description,
          isStudyAvailable: item.attributes.isStudyAvailable,
          targetLink: item.attributes.targetLink,
        })),
        totalCount: data.meta.pagination.total
      }
      
  
      return formatCaseStudies;
    } catch (error) {
      console.error("Error fetching case studies:", error);
      throw error;
    }
  };

  //test 