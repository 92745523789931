import { FC, useCallback,useEffect, useState } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './case-studies-section.scss'
import OurWorkCarousel  from "../common-components/carousels/our-work-carousel"
import { CaseStudy } from "../../model/case-studies"
import { GlueGradientButton } from "../common-components/buttons/button"
import { motion } from "framer-motion"
import { OurWorkSectionId } from "../../model/section-ids"
import useWindowDimensions from "../../utils/window-dimensions"
import { isMobile } from "../../utils/determine-mobile-tablet"
import CaseStudyCard from "../common-components/content-cards/case-study-cards/case-study-card"
import { fetchCaseStudies } from "../backend-services/casestudiesService"

const CASE_STUDIES_PER_ROW = 3
const DesktopCaseStudies:FC = () => {
    const [indexValueToDisplay, setIndexValueToDisplay] = useState(0)
    const [caseStudyArrayToDisplay, setCaseStudyToDisplay] = useState<CaseStudy[]>([])
    const [loadMore, setLoadMore] = useState(true)
    const [totalCaseStudies, setTotalCaseStudies] = useState(0)
    const [loading, setLoading] = useState(false)

    const updateCardsToDisplay = () => {
        setIndexValueToDisplay(indexValueToDisplay + CASE_STUDIES_PER_ROW);
        setLoadMore(true)
    }


    const fetchData = useCallback(async (offsetValue: number) => {
        try {
            setLoading(true)
            const fetchedCaseStudies= await fetchCaseStudies(CASE_STUDIES_PER_ROW, offsetValue);
            setTotalCaseStudies(fetchedCaseStudies.totalCount)
            setCaseStudyToDisplay([...caseStudyArrayToDisplay, ...fetchedCaseStudies.caseStudies])
        } catch (err) {
        console.error("Error fetching case studies:", err);
        // Use static fallback on error without setting an error message
        // staticCaseStudies is already set as default state
        }
        finally{
            setLoading(false)
            setLoadMore(false)
        }
    },[ caseStudyArrayToDisplay]);

    useEffect(()=>{
       if(loadMore) fetchData(indexValueToDisplay)
    },[indexValueToDisplay, loadMore, fetchData])

    return(
        <><div className="casestudy-titles">
                    <h1>CASE STUDIES</h1>
                    <p>Deep dive into our most recent projects</p>
                </div>
                <div className='casestudies-wrapper'>
                {caseStudyArrayToDisplay.map((caseStudy, index) => 
                        <motion.div
                            initial={{opacity:0}}
                            animate={{opacity:1}}
                            transition={{duration: 1}}
                            key={index}
                        >
                            <CaseStudyCard key={index} targetLink={caseStudy.targetLink} backgroundImage={caseStudy.image} clientLogo={caseStudy.clientLogo} clientTitle={caseStudy.clientTitle} studyArea={caseStudy.studyArea} description={caseStudy.description} isStudyAvailable={caseStudy.isStudyAvailable}/>
                        </motion.div>
                    )} 
                </div>
                {loading && <div>Loading...</div>}
        {indexValueToDisplay + CASE_STUDIES_PER_ROW < totalCaseStudies && <GlueGradientButton text={"Load More"} targetUrl={""} isTargetExternal={false} setTextWhite={false} onClickEvent={updateCardsToDisplay}/>}
        </>
    )
}


const MobileCaseStudies:FC = () => {

    function chunk(array:CaseStudy[], chunkSize: number) {
        const size = Math.ceil(array.length / chunkSize)
        const chunks = new Array(size).fill(0);
        return chunks.map((_, index) => {
            const start = index * chunkSize;
            const end = (index + 1) * chunkSize;
            return array.slice(start, end);
        })
    }

    const [caseStudies, setCaseStudies] = useState<CaseStudy[]>([])
    const [totalCaseStudies, setTotalCaseStudies] = useState(1)
    const [offsetValue, setOffsetValue] = useState(0)
    const [loadMore, setLoadMore] = useState(true)
    const [loading, setLoading] = useState(false)

    const [chunkedIntoRowCaseStudies, setChunkedIntoRowCaseStudies] = useState<CaseStudy[][]>([]);

    
    const [indexRowToDisplay, setIndexRowToDisplay] = useState(1)
    const fetchData = useCallback(async (offsetValue: number) => {
        try {
            setLoading(true)
            const fetchedCaseStudies= await fetchCaseStudies(CASE_STUDIES_PER_ROW, offsetValue);
            setTotalCaseStudies(fetchedCaseStudies.totalCount)
            setCaseStudies([...caseStudies, ...fetchedCaseStudies.caseStudies])
            setOffsetValue(offsetValue + CASE_STUDIES_PER_ROW)
            
        } catch (err) {
        console.error("Error fetching case studies:", err);
        // Use static fallback on error without setting an error message
        // staticCaseStudies is already set as default state
        }
        finally{
            
            setLoading(false)
            setLoadMore(false)
        }
    },[caseStudies, setCaseStudies]);

    const updateRowsToDisplay = () => {
        setIndexRowToDisplay(indexRowToDisplay + 1);
        fetchData(offsetValue)
    }

    useEffect(()=>{
        if(loadMore)fetchData(offsetValue)
        setChunkedIntoRowCaseStudies(chunk(caseStudies, CASE_STUDIES_PER_ROW));
    }, [loadMore, fetchData, indexRowToDisplay, offsetValue, setChunkedIntoRowCaseStudies, caseStudies])

    return(
        <>
            {chunkedIntoRowCaseStudies.slice(0,indexRowToDisplay).map((caseStudy, index) => 
                <OurWorkCarousel key={'case-study-carousel' + index} caseStudies={caseStudy}/>
            )}
            {loading && <div>Loading...</div>}    
            {indexRowToDisplay < totalCaseStudies / CASE_STUDIES_PER_ROW && <GlueGradientButton text={"Load More"} targetUrl={""} isTargetExternal={false} setTextWhite={false} onClickEvent={updateRowsToDisplay}/>}
        </>
    )
}

export const CaseStudiesSection: FC = () => {
    
    const isMobileMode = isMobile(useWindowDimensions().width)


    return(
        <>
            <GlueBaseSection id={OurWorkSectionId.CaseStudies}>
               {!isMobileMode && <DesktopCaseStudies/>}
               {isMobileMode && <MobileCaseStudies/>}
            </GlueBaseSection>
        </>

    )
}