import { FC } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './capabilities-section.scss'
import { CommonPageSectionsId } from "../../model/section-ids"
import StrategyBG from './../../assets/home-page/capabilities/strategy-achitecture.jpg'
import ProductTransformationBG from './../../assets/home-page/capabilities/product-transformation.png'
import DataManagementBG from './../../assets/home-page/capabilities/data-management.png'
import IntegrationBG from './../../assets/home-page/capabilities/integration-automation.png'
import { CapabilityCard } from "../common-components/content-cards/capability-card/capability-card"
import { isMobile } from "../../utils/determine-mobile-tablet"
import useWindowDimensions from "../../utils/window-dimensions"
import { CapabilityCarousel } from "../common-components/carousels/mobile-carousels/capability-carousel"

const CardContents = [
    {
        title: "Strategy & Architecture",
        imageUrl: StrategyBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Product Transformation",
        imageUrl: ProductTransformationBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Integration & Automation",
        imageUrl: IntegrationBG,
        containerId: CommonPageSectionsId.MAINCONTENT
    },
    {
        title: "Data Delivery",
        imageUrl: DataManagementBG,
        containerId: CommonPageSectionsId.MAINCONTENT
        
    }
]


const DesktopCapabilities: FC = () => {
    return(
        <div className="capabilities-container">
            {CardContents.map((content, index) => (
                <CapabilityCard title={content.title} backgroundImage={content.imageUrl} targetServiceSectionId={content.containerId} key={"capability-"+index}/>
            ))}
        </div>
    )
}

export const CapabilitiesSection:FC = () => {
    const isMobileMode = isMobile(useWindowDimensions().width)

    return (
        <>
            <GlueBaseSection>
                {!isMobileMode && <DesktopCapabilities/>}
                {isMobileMode && <CapabilityCarousel/>}
            </GlueBaseSection>
        </>
    )
}
