import { FC } from "react"
import GlueBaseSection from "../../common-components/layouts/base-section"
import PeopleCarousel from "../../common-components/carousels/people-carousel"
import './our-people.scss';
import { PeopleSectionId } from "../../../model/section-ids";

export const OurPeopleSection: FC = () => {
    return(
        <GlueBaseSection id={PeopleSectionId.Culture}>
            <h1 className="people-title">OUR CULTURE</h1>
            <PeopleCarousel/>
        </GlueBaseSection>
    )
}