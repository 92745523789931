import { FC } from "react"
import GlueBaseSection from "../common-components/layouts/base-section"
import './ways-of-working-section.scss'
import WayOfWorkingImage from './../../assets/our-work/ways-of-working-top.png'



export const WaysOfWorking: FC = () => {
    return(
        <>
            <GlueBaseSection>
                <h1 className="ways-title">
                        WAYS OF WORKING
                </h1>
                <div className='ways-wrapper'> 
                    <div className="way-of-working-container">
                       
                        
                        <p>
                            At Glue, we collaborate closely with you as a strategic partner to achieve impactful and measurable solutions.
                        </p>
                        <p>
                            Collaboration and trust form the foundation of our approach. Our consultants work to develop and transfer new skills, enhancing your internal capabilities while functioning as an extension of your team. 
                        </p>
                        <p>
                            We combine industry-leading expertise with a commitment to sustainable practices, ensuring lasting impact and growth. Through thought-leadership and adherence to best practices, we drive the future of technology forward, together.  
                        </p>
                        
                    </div>
                    <div className="ways-image-container">
                        <img className="ways-image" src={WayOfWorkingImage} alt="ways of working"></img>
                    </div>
                </div>
            </GlueBaseSection>
        </>

    )
}