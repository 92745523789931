import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import './contact-form.scss';
import styled from "styled-components";
import useWindowDimensions from "../../../utils/window-dimensions";
import { isMobile } from "../../../utils/determine-mobile-tablet";
import { GlueStateContext } from "../../../utils/context/glue-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { GlueGradientButton } from "../buttons/button";

declare global {
    interface Window {
        onCaptchaChange: (token: string) => void;
    }
}

//comment

const StyledMenu = styled.div.attrs({ className: "contact-form-menu" }) <{
    $open: boolean;
    $isMobile: boolean;
    $translateValueOpen: string;
    $translateValueClosed: string;
}>`
  width: 100%;
  backdrop-filter: blur(10px);
  transform: ${(p) => (p.$open ? `translateX(0%)` : `translateX(100%)`)};
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 9999;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

type ContactProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ContactForm: FC<ContactProps> = ({ open, setOpen }) => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [jobRole, setJobRole] = useState('');
    const [message, setMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState<string | null>(null); // reCAPTCHA token
    const { glueState } = useContext(GlueStateContext);
    const isMobileMode = isMobile(useWindowDimensions().width);
    const [formStatus, setFormStatus] = useState('');
    const [showModal, setShowModal] = useState(false); // State for the modal
    const [scrollPosition, setScrollPosition] = useState(0); // Store scroll position

    // Validation schema
    const EmailSchema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string(),
        email: yup.string().email().required(),
        phoneNumber: yup.string(),
        company: yup.string().required(),
        jobRole: yup.string(),
        message: yup.string().required()
    });

    const { register, formState: { errors } } = useForm({
        resolver: yupResolver(EmailSchema)
    });

    // Dynamically load the reCAPTCHA v2 script
    useEffect(() => {
        const loadRecaptcha = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        };

        loadRecaptcha();

        // Expose the callback function globally
        window.onCaptchaChange = handleCaptchaChange;
    }, []); // This runs only once when the component mounts

    const handleCaptchaChange = (token: string) => {
        setCaptchaToken(token); // Store the reCAPTCHA token in state
    };

    const resetFields = () => {
        setName('');
        setSurname('');
        setEmail('');
        setCompany('');
        setPhoneNumber('');
        setJobRole('');
        setMessage('');
        glueState.isRFPEmail=false
    };

    // Save the scroll position when the form opens
    useEffect(() => {
        if (open) {
            setScrollPosition(window.pageYOffset); // Save the current scroll position
        }
    }, [open]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!captchaToken) {
            setFormStatus("Please complete the CAPTCHA challenge.");
            return;
        }

        // Prepare the form data
        const formData = {
            Name: name,
            Surname: surname,
            Email: email,
            Company: company,
            Number: phoneNumber,
            JobRole: jobRole,
            Message: message,
            mainReceiver: glueState.isRFPEmail ?"GlueProcurement":"GlueOperations",
            captchaToken, // Include the reCAPTCHA token
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_STRAPI}/api/contact-forms`, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: formData }),
            });

            const result = await response.json();

            if (response.ok) {
                // Handle success
                setFormStatus('Form submitted successfully!');
                resetFields(); // Reset the form after successful submission

                // Close the form window and show success modal
                setOpen(false);  // Close the form window
                setShowModal(true);  // Show the success modal

                // Automatically close the modal and restore scroll position after 2 seconds
                setTimeout(() => {
                    setShowModal(false);
                    window.scrollTo(0, scrollPosition);  // Restore the previous scroll position
                }, 2000);
            } else {
                // Handle errors
                setFormStatus(`Error: ${result.error.message}`);
            }
        } catch (error) {
            if (error instanceof Error) {
                setFormStatus(`Error: ${error.message}`);
            } else {
                setFormStatus('An unexpected error occurred.');
            }
        }
    };

    useEffect(() => {
        if (open) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'scroll';
    }, [isMobileMode, open]);

    return (
        <>
            <StyledMenu $open={open} $isMobile={isMobileMode} $translateValueOpen={isMobileMode ? "0%" : "100%"} $translateValueClosed={isMobileMode ? "100%" : "200%"} >
                <div className="form-wrapper">
                    <FontAwesomeIcon className="contact-close-button" onClick={() => setOpen(false)} icon={faXmark} />
                    <h2> {glueState.formTitle} </h2>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-fields-container">
                            <div className="form-group">
                                <label htmlFor="name">Name *</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="name">Surname</label>
                                <input
                                    type="text"
                                    id="surname"
                                    value={surname}
                                    onChange={(e) => setSurname(e.target.value)}
                                    placeholder="Enter your surname"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Business Email *</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Enter your email"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="company">Company *</label>
                                <input
                                    type="text"
                                    id="company"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    required
                                    placeholder="Enter your company name"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input
                                    type="text"
                                    id="phoneNumber"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter your phone number"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="jobRole">Job Role</label>
                                <input
                                    type="text"
                                    id="jobRole"
                                    value={jobRole}
                                    onChange={(e) => setJobRole(e.target.value)}
                                    required
                                    placeholder="Enter your job role"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message *</label>
                                <textarea
                                    id="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                />
                            </div>

                            {/* reCAPTCHA v2 checkbox */}
                            <div
                                className="g-recaptcha"
                                data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                data-callback="onCaptchaChange"
                            ></div>

                            <GlueGradientButton text={"Submit"} isTargetExternal={false} setTextWhite={true} />
                            {formStatus && <p>{formStatus}</p>} {/* Display form status */}
                        </div>
                    </form>
                </div>
            </StyledMenu>

            {/* Modal for success message */}
            {showModal && (
                <Modal>
                    <h3>Form Submitted Successfully!</h3>
                </Modal>
            )}
        </>
    );
};
