import GlueBaseSection from "../common-components/layouts/base-section";
import '../environments-page/we-care.scss'
import WeCareImage from '../../assets/environments-page/environment-we-care.png'
import { EnvironmentSectionId } from "../../model/section-ids";

export const WeCare: React.FC = () => {
    return (
        <GlueBaseSection id={EnvironmentSectionId.WeCare}>
            <div className="content-wrapper-we-care">
                <div className="flex-container">
                    <div className="text-column">
                        <h1 className="title">WE CARE</h1>
                        <p className="description">

                            Climate change and environmental sustainability present challenges and opportunities for everyone, and at Glue Reply, we believe that the business community plays a key role in making positive changes.
                        </p>
                        <p className="description">

                            Glue Reply are proud to be partnered with <a href="https://www.linkedin.com/company/carbonneutralbritain/">Carbon Neutral Britain™</a> to measure and offset our carbon footprint and become certified as a Carbon Neutral Business.
                        </p>
                        <p className="description">

                            This is a significant milestone in our sustainability journey and commitment to taking positive steps to tackle climate change by supporting verified carbon offsetting projects around the world.
                        </p>
                    </div>
                    <div className="image-column">
                        <img className="we-care-image" src={WeCareImage} alt="we care"/>
                    </div>
                </div>
            </div>

        </GlueBaseSection>
    )
}

export default WeCare;