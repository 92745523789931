import {FC, useEffect} from 'react'
import GluePage from '../components/glue-page/page'
import { GluePages } from '../model/glue-pages'
import { InsightsCardsSection } from '../components/insights-page/insights-cards-section'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../utils/analyticsRequest'


export const GlueInsights: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-insights", "Insights", cookies)    
      });
    return(
        <GluePage headerLocation={GluePages.INSIGHTS}>
            <div>
                <InsightsCardsSection/>
            </div>
        </GluePage>
    )
}