import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './footer.scss';
import Logo from '../../assets/footer/Logo.png';
import companypdf from '../../assets/companyinfo/company-information.pdf';
//import Instagram from '../../assets/footer/Instagram_Icon.png'
//import X from '../../assets/footer/X_Icon.png'
//import LinkedIn from '../../assets/footer/LinkedIn_Icon.png'
import { CommonPageSectionsId } from '../../model/section-ids';
import GlueBaseSection from '../common-components/layouts/base-section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { GlueStateContext } from '../../utils/context/glue-context';
import { useNavigate } from 'react-router-dom';


type SocialIconProps = {
  src: any;
  alt: string;
  onClick: () => void;
};


const SocialIcon: React.FC<SocialIconProps> = ({ src, alt, onClick }) => {

  const className = alt.toLowerCase().replace(/[^a-z]+/g, '-') + '-icon';

  return (
    <button className={`social-icon-button ${className}`} onClick={onClick}>
      <FontAwesomeIcon icon={src} className="social-icon-image" />
    </button>
  )
};

type FooterLinkProps = {
  text: string;
  subtext?: string;
  onClick: () => void;
};

const FooterLink: React.FC<FooterLinkProps> = ({ text, subtext, onClick }) => (
  <button className="footer-link-button" onClick={onClick}>
    <span className="footer-link-text">{text}</span>
    {subtext && <span className="footer-link-subtext">{subtext}</span>}
  </button>
);

type FooterColumnProps = {
  title: string;
  links: FooterLinkProps[];
};

const FooterColumn: React.FC<FooterColumnProps> = ({ title, links }) => (
  <div className="footer-column-wrapper">
    <h3 className="footer-column-title">{title}</h3>
    <div className="footer-column-links">
      {links.map((link, index) => (
        <FooterLink key={index} {...link} />
      ))}
    </div>
  </div>
);


const Footer: React.FC = () => {

  const handleSocialIconClick = (iconName: string) => {
    console.log(`Clicked on ${iconName}`);
    switch (iconName) {
      case "LinkedIn":
        window.open("https://www.linkedin.com/company/glue-reply");
        break;
      case "Twitter/X":
        window.open("https://twitter.com/GlueReply");
        break;
      case "Instagram":
        window.open("https://www.instagram.com/gluereply")
        break;
      default:
        console.log("Something went wrong in the icon click.")
        break;
    }
  };

  const handleLinkClick = (link: string) => {
    window.open(link)
  };

  const socialIcons = [
    { src: faLinkedin, alt: "LinkedIn", onClick: () => handleSocialIconClick("LinkedIn"), },
    { src: faXTwitter, alt: "Twitter/X", onClick: () => handleSocialIconClick("Twitter/X") },
    { src: faInstagram, alt: "Instagram", onClick: () => handleSocialIconClick("Instagram") },
  ];

  const { glueState } = useContext(GlueStateContext)
  const navigate = useNavigate();


  const footerColumns = [
    {
      title: "COMPANY",
      links: [
        { text: "About Glue Reply", onClick: () => navigate("/glue-company") },
        { text: "Offices", onClick: () => { glueState.elementToScroll = "office-section"; navigate("/glue-company"); } },
      ],
    },
    {
      title: "PRIVACY",
      links: [
        { text: "Privacy & Cookie Policy", onClick: () => handleLinkClick("https://www.reply.com/en/privacy-policy") },
        { text: "Privacy Notice", subtext: "Client", onClick: () => handleLinkClick("https://www.reply.com/contents/Informativa_Clienti_eng.pdf") },
        { text: "Privacy Notice", subtext: "Supplier", onClick: () => handleLinkClick("https://www.reply.com/contents/Informativa_Fornitori_eng.pdf") },
        { text: "Privacy Notice", subtext: "Candidate", onClick: () => handleLinkClick("https://www.reply.com/en/privacy-notice-candidate") },
      ],
    },
    {
      title: "LEGAL",
      links: [
        { text: "Accessibility Statement", onClick: () => handleLinkClick("https://www.reply.com/en/accessibility") },
        { text: "CCPA Privacy Notice", onClick: () => handleLinkClick("https://www.reply.com/en/CCPA-privacy-notice") },
        { text: "Modern Slavery Act Transparency Policy", subtext: "(UK and IR)", onClick: () => handleLinkClick("https://www.reply.com/contents/Modern_Slavery_Statement.pdf") },

      ]
    },
    {
      title: "CONTACT",
      links: [
        { text: "Contact Us", onClick: () => { glueState.formTitle = "Contact Us"; glueState.setOpenContact!(true) } },
        { text: "Join Us", onClick: () => navigate("/glue-careers") },
      ],
    },
  ];

  const location = useLocation(); // Get the current route

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Enables smooth scrolling
    });
  };

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      scrollToTop();
    }
  };


  return (
    <>
      <GlueBaseSection backgroundColor='#F5F5F5'>

        <footer className="footer-container" id={CommonPageSectionsId.FOOTER}>
          <div className="footer-wrapper">
            <div className="footer-content">
              <div className="logo-column">
                <div className="logo-wrapper">
                  <div> <NavLink to="/" onClick={handleLogoClick}><img className="logo" loading="lazy" src={Logo} alt="Company Logo" /> </NavLink></div>
                  <div className="social-icons-wrapper">
                    {socialIcons.map((icon, index) => (
                      <SocialIcon key={index} {...icon} />
                    ))}
                  </div>
                </div>
              </div>
              <div className="links-column">
                <div className="footer-links-wrapper">
                  {footerColumns.map((column, index) => (
                    <FooterColumn key={index} {...column} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </footer>

      </GlueBaseSection>

      <GlueBaseSection backgroundColor='black'>
        <div className='copyright-section'>
          <div className='copyright-text'>
            <span>Reply © 2024 - </span>
            <a role="button" href = {companypdf} target="_blank" rel="noreferrer" >Company information</a>
          </div>
        </div>
      </GlueBaseSection>
    </>
  );
};

export default Footer;