import { FunctionComponent } from "react";
import "./continuous-learning.scss";
import backgroundImage from '../../../assets/people-page/continuous-learning.png';
import GlueBaseSection from "../../common-components/layouts/base-section";
import { PeopleSectionId } from "../../../model/section-ids";
import useWindowDimensions from "../../../utils/window-dimensions";
import { isMobile } from "../../../utils/determine-mobile-tablet";

export type ContinuousLearningType = {
  className?: string;
};

const ContinuousLearning: FunctionComponent<ContinuousLearningType> = ({ className = "learning" }) => {
  const isMobileMode = isMobile(useWindowDimensions().width);

  return (
    <GlueBaseSection id={PeopleSectionId.ContiniousLearning}>
      <div className={`continuous-learning-root ${className}`}>
        <section className="continuous-learning-section">
          {!isMobileMode &&
            <img
              className="learning-background-icon"
              loading="lazy"
              alt="Continuous Learning"
              src={backgroundImage}
            />}
          <div className="employee-growth">
            <h1 className="continuous-learning-title">CONTINUOUS LEARNING</h1>
            <div className="to-support-our-container">
              <p className="to-support-our">{`To support our employee’s professional growth and enhance collaboration within our teams, we continuously invest in certifications and business accreditations. We are a platinum member of the British Computer Society, and a member of the Business Architects Guild.`}</p>
              <p className="middle-paragraph">
                {`At Glue Reply, we recognise that nurturing soft skills is crucial for sustained growth, which is why we offer tailored learning programmes, from effective communication to adaptive leadership.`}
                <br />
                <br />
                {`We take this a step further with Fresh Brew Fridays, a regular session to practice presentation skills and encourage diversity of thought, no matter the topic!`}
              </p>
            </div>
          </div>
          {isMobileMode &&
            <img
              className="learning-background-icon"
              loading="lazy"
              alt="Continuous Learning"
              src={backgroundImage}
            />}
        </section>
      </div>

    </GlueBaseSection>
  );
};

export default ContinuousLearning;
