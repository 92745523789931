import { FC } from 'react';
import { useCookies } from 'react-cookie';
import './cookie-consent-component.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const CookieConsent: FC = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent", "cookieDisabled"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  const dontGiveConsent = () => {
    setCookie("cookieDisabled", true, { path: "/" });
  }

  if (cookies.cookieDisabled){
    return <></>
  }

  return (
    <div className="cookie-consent">
      <div className='cookie-content'>
        <FontAwesomeIcon className='cookie-close-button' icon={faXmark} onClick={()=>dontGiveConsent()}/>
      <div className='cookie-statement'><p>
      This website uses technical cookies to make its services simple and effective. Additionally, after explicit consent, it makes use of third-party cookies and tracking technologies for retargeting purposes on third-party platforms. 
      This website does not use non-technical first-party cookies. By selecting "Accept Essential Cookies" you agree to the use of third-party cookies and tracking technologies.
      By clicking on the X at the top right of this banner, or by clicking on "Decline Essential Cookes", your navigation will continue and you don't allow the use of third-party cookies and tracking technologies.
      For more information about the processing and the opt-out option you may read our 
        <a href={"https://www.reply.com/en/privacy-policy"}> Privacy & Cookie Policy. </a>
      </p></div>
        <div className='cookie-button-container'>
          <button className="decline-btn" onClick={dontGiveConsent}>
              Decline Cookies
          </button>
          <button className="accept-btn" onClick={giveCookieConsent}>
              Accept Cookies
          </button>
          
        </div>
      </div>
      
    </div>
  );
};

export default CookieConsent;