import { FunctionComponent } from "react";
import "./life-at-reply.scss";
import backgroundImage from '../../../assets/people-page/Life-at-Glue.png';
import GlueBaseSection from "../../common-components/layouts/base-section";
import { PeopleSectionId } from "../../../model/section-ids";
import useWindowDimensions from "../../../utils/window-dimensions";
import { isMobileOrTablet } from "../../../utils/determine-mobile-tablet";

export type LifeAtGlueType = {
  className?: string;
};

const LifeAtGlue: FunctionComponent<LifeAtGlueType> = ({ className = "life-at-glue-root" }) => {
  const isMobileOrTabletMode = isMobileOrTablet(useWindowDimensions().width);


  return (
    <GlueBaseSection id={PeopleSectionId.LifeAtGlue}><div className={`life-at-glue-root ${className}`}>
      <div className="life-at-glue-section">
        <div className="life">
          <h1 className="life-at-glue-title">LIFE AT GLUE</h1>
          <p className="at-glue-reply">
            At Glue Reply, we’ve cultivated an environment where creativity
            thrives, diversity flourishes and collaboration sparks innovation.
          </p>
          {isMobileOrTabletMode &&
          <div className="reply">
            <img
              className="root-child"
              loading="lazy"
              alt="Life at Glue"
              src={backgroundImage}
            />
          </div>
        }
          <div className="through-our-monthly-container">
            <p className="through-our-monthly">
              Through our monthly socials, fitness challenges and annual events,
              we’ve created a community which celebrates our milestones and
              collective achievements.
            </p>
            <p className="we-actively-support">
              We actively support mental wellbeing in the workplace and foster a
              culture of support and openness, so that our teams succeed both
              professionally and personally.
            </p>
            <p className="our-regular-wellbeing-walks">
              Our regular wellbeing-walks and mental health training ensures that
              we take time to talk, listen, and connect with our colleagues.
            </p>
          </div>
        </div>
        {!isMobileOrTabletMode &&
          <div className="reply">
            <img
              className="root-child"
              loading="lazy"
              alt="Life at Glue"
              src={backgroundImage}
            />
          </div>
        }
      </div>
    </div>
    </GlueBaseSection>
  );
};

export default LifeAtGlue;
