import React from 'react';
import './benefits-comp.scss';
import GlueBaseSection from '../../common-components/layouts/base-section';

interface BenefitItemProps {
  icon: string;
  title: string;
}

const BenefitItem: React.FC<BenefitItemProps> = ({ icon, title }) => (
  <article className="benefit-wrapper">
    <div className="icon-wrapper">
      <img src={icon} alt="" className="benefit-icon" />
    </div>
    <h2 className="benefit-title">{title}</h2>
  </article>
);

const benefitsData: BenefitItemProps[] = [
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a06a0ee953cafab9820c7cff45128e6dbe32269ecf4f3c4d65e8104ba6359c7f?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Flexible\nHybrid Working" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e8900940a39c99a0cfcc802bcb285c609016e8fd0bdaaae4157efd40dabc41a4?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Discounted Gym Memberships" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/53483cc47663a4639dc961733d00bb269f87f7515d6a4dc4f5f491ad4ec82f68?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Funded Qualifications" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2be59acd3c9fffcd0167c80aa08a07b2d7ac5b8e4722637b0732d0a2c3b669e0?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Electric Vehicle Scheme" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/1e7614b6ffba4a7812a575fad70c736fdbef2bcf4609b5fdd0e90df83cfc9f3e?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Bonus Schemes" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9f0e5b83a307473b2629933e27e18094adc1ec8e00da4cfc95cf8bade2a7805f?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Regular Socials\n& Events" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2be67280e3798911515e2bfb81640c28256b543dd4ceb737a2b2036cab2b140f?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Cycle to Work\nScheme" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7a98fc04ccf6769fa686d2416a299799b8a188c96a9634e5435ff3ac584b8827?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Healthcare & Med Insurance" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0b69bf06727a7a9471628b3331527a42ad76c2d1d7be2d326ff01fe3b0197d45?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Private Pension Scheme" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/61ace291d8d2a41042f8756cd7bef37f4ae39306285ac43eaa9374927c6a23d2?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Referral Bonus" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6545b9cc5087570c496412b32014e160dda2c01e276af6e9186714bf582325d4?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Additional Annual Leave" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f33f70b0d58352ccc0d7edac8305a2c7afea4ddbb98eb603a32f2231f94184b7?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Season Ticket Loan" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/331f6934a743e32fe3d32938e83032cae2a047487f8e6aca8db0bdf57c02a2be?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Financial Advisory Service" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/918656fde9c3069ddde2eca02a154f23cba0f72af0dd9b249953cac672b319cc?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "Employee Assistance Programme" },
  { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e898ea49513221a5dc668a6077672175b737f2555334e78fd935f8021e1d6982?apiKey=4641169c81374835b0d9ae8f2ea89954&", title: "And More" },
];

const BenefitsComp: React.FC = () => {
  return (
    <GlueBaseSection backgroundImage='https://cdn.builder.io/api/v1/image/assets/TEMP/20da6f94427509a175ee4dc34a80be576e9f8c37594166d5407768e31277d8d8?apiKey=4641169c81374835b0d9ae8f2ea89954&' applyBackgroundBlackOverlay={true}>
          <div className="benefits-grid">
            <h1 className="benefits-title">BENEFITS</h1>
            {benefitsData.map((benefit, index) => (
              <BenefitItem key={"benefit-"+index} icon={benefit.icon} title={benefit.title} />
            ))}
          </div>
    </GlueBaseSection>
  );
}

export default BenefitsComp;