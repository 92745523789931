import { Routes, Route, BrowserRouter } from "react-router-dom"
import { HomePage } from "./pages/home"
import { GlueCareers } from "./pages/careers"
import { GlueInsights } from "./pages/insights"
import { GlueWork } from "./pages/our-work"
import { GlueServices } from "./pages/services"
import "./assets/fonts/Alaska-BoldContrast.otf"
import "./assets/fonts/Alaska-ExpandedMedium.otf"
import "./assets/fonts/Alaska-Expanded.otf"
import "./assets/fonts/Alaska-LightContrast.otf"
import "./assets/fonts/Alaska-MediumContrast.otf"
import './App.css'
import { GlueStateContextProvider } from "./utils/context/glue-context"
import { GlueEnvironment } from "./pages/about-us/environment"
import { GlueCompany } from "./pages/about-us/company"
import { GluePeople } from "./pages/about-us/people"
import ReactGA from 'react-ga4'


function App() {

  ReactGA.initialize([{trackingId: 'G-60CHEYET0G'}])

  return (
    <GlueStateContextProvider>
    <div className="App">
      
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<HomePage />} />
            <Route path="/glue-services" element={<GlueServices />} />
            <Route path="/glue-careers" element={<GlueCareers />} />
            <Route path="/glue-insights" element={<GlueInsights />} />
            <Route path="/glue-our-work" element={<GlueWork />} />
            <Route path="/glue-environment" element={<GlueEnvironment />} />
            <Route path="/glue-company" element={<GlueCompany/>} />
            <Route path="/glue-people" element={<GluePeople />} />
          </Route>
        </Routes>
        
      </BrowserRouter>
    </div>
    </GlueStateContextProvider>
  )
}

export default App