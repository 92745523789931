import React from "react";
import parse from 'html-react-parser';
import '../services-cards/enabler-card.scss';
import Gradients from "../../assets/services-page/gradients";

interface EnablerCardProps {
    image: string;
    title: string;
    textDescription: string;
    detailsIcons: Array<string>;
    iconDescriptions: Array<string>;
}

interface ExpandIconProps {
    type: string;
  }

const EnablerCard: React.FC<EnablerCardProps> = ({
    image,
    title,
    textDescription,
    detailsIcons,
    iconDescriptions,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const ExpandIcon: React.FC<ExpandIconProps> = ({type}) => (
        <svg className={type} width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Gradients />
            <circle id="circle" opacity="1" cx="25" cy="25" r="24.5" transform="rotate(90 25 25)" fill="red" fillOpacity="0" stroke="black"/>
            <path id="horizontal" d="M15 24H35.095" stroke="black" strokeWidth="3" strokeLinecap="round"/>
            {!expanded && <path id="vertical" d="M25 35.0947V13.9997" stroke="black" strokeWidth="3" strokeLinecap="round"/>}
        </svg>
    );

    return (
        <section className={`enabler-card ${expanded ? 'expanded' : ''}`}>
            <div className="content-wrapper">
                <div className={`flex-container ${expanded ? 'expanded' : ''}`} onClick={handleExpandClick}>
                    <div className="image-wrapper">
                        <img className="card-image" loading="lazy" src={image} alt="Card" />
                    </div>
                    <div className="inner-wrapper">
                        <p className="title">{title}</p>
                        {!expanded && (
                        <div className="expand-button">
                            <ExpandIcon type="plus"/>
                        </div>
                        )}
                    </div>
                </div>
                <div className={`expanded-content ${expanded ? 'expanded' : ''}`} onClick={handleExpandClick}>
                    <h2 className="text-details">
                        {parse(textDescription)}
                    </h2>
                    <div className="icon-container">
                        {detailsIcons.map((detail, index) => (
                            <div key={index} className="icon-inner-wrapper">
                                <img className="icon" src={detail} alt="Details icon" />
                                <p className="icon-description">{iconDescriptions[index]}</p>
                            </div>
                        ))}
                    </div>
                    <div className="button-label">
                        <button className="in-card-expand-button">
                            <ExpandIcon type='minus'/>
                        </button>
                        <label className="show-less">
                            Show less
                        </label>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EnablerCard;