import { FC, useContext } from "react";
import './submenu-column.scss';
import { NavLink } from "react-router-dom";
import { GlueStateContext } from "../../../utils/context/glue-context";

type SubmenuProps = {
    title: string;
    titleTarget: string;
    links: string[];
    linksTarget: string[];
}

export const SubmenuColumn: FC<SubmenuProps> = ({ title, titleTarget, links, linksTarget }) => {
    const { glueState, setGlueState } = useContext(GlueStateContext);

    function scrollToTargetElement(index: number) {
        const targetScrollId = linksTarget[index];
        glueState.elementToScroll = targetScrollId;
        setGlueState(glueState);
        const targetScrollIfClickedOnCurrentPage = document.getElementById(targetScrollId);
        targetScrollIfClickedOnCurrentPage?.scrollIntoView({ behavior: 'smooth' });
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }

    return (
        <>
            <div className="submenu-column-container">
                <h3 className="column-title">
                    <NavLink to={titleTarget} onClick={scrollToTop}>{title}</NavLink>
                </h3>
                {links.map((link, index) => {
                    const target = linksTarget[index];

                    if (target.startsWith('http')) {
                        return (
                            <a href={target} key={index} target="_blank" rel="noopener noreferrer" className="submenu-scroll-link">
                                {link}
                            </a>
                        );
                    } else {
                        return (
                            <NavLink to={titleTarget} key={index}>
                                <div className="submenu-scroll-link" onClick={() => scrollToTargetElement(index)}>
                                    {link}
                                </div>
                            </NavLink>
                        );
                    }
                })}
            </div>
        </>
    );
};
