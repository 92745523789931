export function isMobile(screenWidth: number){
    return screenWidth <= 740;
}

export function isMobileOrTablet(screenWidth: number){
    return screenWidth < 1000;
}

export function isTablet(screenWidth: number){
    return screenWidth > 740 && screenWidth < 1000;
}

export function isDesktop(screenWidth: number){
    return screenWidth >= 1000;
}