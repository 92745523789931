import GlueBaseSection from "../common-components/layouts/base-section";
import '../environments-page/sustainable-insights.scss'
import WhitePaper from './../../assets/insights/white-paper.png'
import Article from './../../assets/insights/article.png'
import BestPractices from './../../assets/insights/best-practices.png'
import InsightCard from "../common-components/content-cards/insights-cards/insight-card";
import { EnvironmentSectionId } from "../../model/section-ids";
import { isMobile } from "../../utils/determine-mobile-tablet";
import useWindowDimensions from "../../utils/window-dimensions";
import { FC, useEffect, useState } from "react";
import { insightCards } from "../../model/insights-cards";
import { InsightCarousel } from "../common-components/carousels/mobile-carousels/insight-carousel";
import { InsightsType } from "../../model/insights";
import { fetchInsights } from "../backend-services/insightsService";
const insights = [
    {
        image: Article,
        borderAndText: "#008395",
        linkText: "Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
        linkTarget: "https://www.reply.com/en",
        cornerText: "Article"
    },

    {
        image: BestPractices,
        borderAndText: "#7353A7",
        linkText: "Dealing with Mass Automation",
        linkTarget: "https://www.reply.com/en",
        cornerText: "Best Practices"
    },

    {
        image: WhitePaper,
        borderAndText: "#2B763B",
        linkText: "Unlocking AI Readiness with Business Capability Modelling",
        linkTarget: "https://www.reply.com/en",
        cornerText: "White Paper"
    },
    {
        image: Article,
        borderAndText: "#008395",
        linkText: "Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
        linkTarget: "https://www.reply.com/en",
        cornerText: "Article"
    },

    {
        image: BestPractices,
        borderAndText: "#7353A7",
        linkText: "Dealing with Mass Automation",
        linkTarget: "https://www.reply.com/en",
        cornerText: "Best Practices"
    },
]

type insightProps = {
    insightCards: InsightsType[]
}

export const SustainableInsights: React.FC<insightProps> = ({insightCards}) => {
    return (
        <GlueBaseSection id={EnvironmentSectionId.Sustainability}>
            <div className="content-wrapper-sustainable-insights">
                <div className="flex-container">
                    <h1 className="sustainable-insights-title">
                        SUSTAINABLE INSIGHTS
                    </h1>
                    <div className='insights-cards-container'>
                        {insightCards.map((insight, index) =>
                            <InsightCard key={'insight-' + index} image={insight.image} borderAndTextColor={insight.borderAndText} cornerText={insight.insightType} linkText={insight.linkText} linkTarget={insight.linkTarget} />
                        )}
                    </div>
                </div>
            </div>

        </GlueBaseSection>
    )
}




const SustainableInsightsHome: FC = () => {
    const isMobileMode = isMobile(useWindowDimensions().width);
    const staticInsights = insightCards.insights.slice(0, 3);

    const [insights, setInsights] = useState<InsightsType[]>(staticInsights);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Exclude sustainable insights
          const fetchedInsights = await fetchInsights(20,0,false,true); // Fetch 3 insights
          setInsights(fetchedInsights.insights);
        } catch (err) {
          console.error("Error fetching insights:", err);
          // staticInsights is already set as default state
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);


    return (
        <div className="insight-carousel-wrapper">
            {loading && <div>loading insights...</div>}
            <GlueBaseSection>
                {!isMobileMode && <SustainableInsights insightCards={insights}/>}
                {isMobileMode && <InsightCarousel insightCards={insights} />}
            </GlueBaseSection>
        </div>

    )

}

export default SustainableInsightsHome;