import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import {FC} from 'react'
import { Layout } from 'antd'
import { GlueHeader } from '../header/header'
import { GluePages } from '../../model/glue-pages'
import { Navbar } from '../navigation/navbar'
import './page.scss'

import Footer from '../footer/footer'
import { CommonPageSectionsId } from '../../model/section-ids'
import { GlueStateContext } from '../../utils/context/glue-context'
import { ContactForm } from '../common-components/contact-form/contact-form'
import CookieConsent from '../cookie-component/cookie-consent-component'
import { useCookies } from 'react-cookie'
/*
    Universal Base component for Glue Reply pages
    Every Glue Reply page will be based on this reusable component
*/

type GluePageProps = PropsWithChildren & {
    headerLocation: GluePages,
    headerTextSlides?:[{
        headerText: string
    }],
    updateScrollTargetElement?:React.Dispatch<React.SetStateAction<string>> 

}

export const GluePage: FC<GluePageProps> = ({children, headerLocation, updateScrollTargetElement}) => {
    const { glueState, setGlueState } = useContext(GlueStateContext);
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);
    const [openContactForm, setOpenContactForm] = useState(false)
    glueState.setOpenContact = setOpenContactForm
    setGlueState(glueState)

    
    

    useEffect(() => {
        const targetScroll = document.getElementById(glueState.elementToScroll)
        targetScroll?.scrollIntoView({ behavior: "smooth" })
    }, [glueState.elementToScroll])

    return(
        <>
            <Layout>
                <ContactForm open={openContactForm} setOpen={setOpenContactForm }/>
                <Navbar isContactFormOpen={openContactForm} updateScrollTargetElementOnDrodown={updateScrollTargetElement} setOpenContact={setOpenContactForm}/>
                <GlueHeader headerLocation={headerLocation}/>
                <main id='gluepage-main-content'>
                    <div id={CommonPageSectionsId.MAINCONTENT}>
                        {children}
                    </div>
                </main>

                <Footer />
                {(!cookies.cookieConsent && !cookies.cookieDisabled) && <CookieConsent/>}

            </Layout>
        </>
    )
}

export default GluePage;