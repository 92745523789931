import React, { FC, useEffect } from 'react';
import GluePage from '../components/glue-page/page';
import Description from '../components/careers-page/description-component/description-comp'
import JoinUs from '../components/careers-page/join-us-component/join-us-comp';
import BenefitsComp from '../components/careers-page/benefits-component/benefits-comp';
import OpenPositions from '../components/careers-page/ideal-role-component/ideal-role-comp'
import { GluePages } from '../model/glue-pages';
import { useCookies } from 'react-cookie';
import { sendPageEvent } from '../utils/analyticsRequest';

export const GlueCareers: FC = () => {
  const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-careers", "Careers", cookies)    
      });
  return (
    <GluePage headerLocation={GluePages.CAREERS}>
      <Description />
      <JoinUs />
      <OpenPositions />
      <BenefitsComp />
    </GluePage>
  );
};

//test