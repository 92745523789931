import React from "react";
import "./enabler-section.scss"
import GlueBaseSection from "../common-components/layouts/base-section";

import EnablerCard from "../services-cards/enabler-card";

// STRATEGY ICONS
import EnablerIcon1 from '../../assets/services-page/enablers/strategy-and-planning-icon.svg'
import StrategyDetailsIcon1 from '../../assets/services-page/enablers/s-and-p-1.svg';
import StrategyDetailsIcon2 from '../../assets/services-page/enablers/s-and-p-2.svg';
import StrategyDetailsIcon3 from '../../assets/services-page/enablers/s-and-p-3.svg';
import StrategyDetailsIcon4 from '../../assets/services-page/enablers/s-and-p-4.svg';

// SUPPORT ICONS
import EnablerIcon2 from '../../assets/services-page/enablers/support-and-maintenance-icon.svg'
import SupportDetailsIcon1 from '../../assets/services-page/enablers/support-1.svg';
import SupportDetailsIcon2 from '../../assets/services-page/enablers/support-2.svg';
import SupportDetailsIcon3 from '../../assets/services-page/enablers/support-3.svg';
import SupportDetailsIcon4 from '../../assets/services-page/enablers/support-4.svg';
import SupportDetailsIcon5 from '../../assets/services-page/enablers/support-5.svg';

// PRODUCT ICONS
import EnablerIcon3 from '../../assets/services-page/enablers/product-centric-change-icon.svg'
import ProductDetailsIcon1 from '../../assets/services-page/enablers/product-centric-change-1.svg';
import ProductDetailsIcon2 from '../../assets/services-page/enablers/product-centric-change-2.svg';
import ProductDetailsIcon3 from '../../assets/services-page/enablers/product-centric-change-3.svg';
import ProductDetailsIcon4 from '../../assets/services-page/enablers/product-centric-change-4.svg';


interface EnablerSectionProps {
    title: string;
    description: string;
}

const EnablerSection: React.FC<EnablerSectionProps> = ({
    title,
    description,
}) => {
    return (
        <GlueBaseSection>
            <section className="enabler-section">
                <div className="content-wrapper">
                    <div className="flex-container">
                        <div className="introduction">
                            <h1 className="title">
                                {title}
                            </h1>
                            <p className="description">
                                {description}
                            </p>
                        </div>
                        <div className="cards">
                            <EnablerCard image={EnablerIcon1} title={"Strategy & Planning"} detailsIcons={[StrategyDetailsIcon1, StrategyDetailsIcon2, StrategyDetailsIcon3, StrategyDetailsIcon4]}
                                textDescription="We empower organisations to seize market and technological opportunities through effective product strategy and planning."
                                iconDescriptions={["Evaluating Opportunities", "Analysing Product Landscape", "Identifying User Needs", "Defining Success Metrics"]} />
                            <EnablerCard image={EnablerIcon2} title={"Support & Maintenance"} detailsIcons={[SupportDetailsIcon1, SupportDetailsIcon2, SupportDetailsIcon3, SupportDetailsIcon4, SupportDetailsIcon5]}
                                textDescription="We offer 24/7 support and maintenance services."
                                iconDescriptions={["Fixing Software Defects", "Product Feature Enhancements", "Online support", "Sec ops support", "Process based support"]} />
                            <EnablerCard image={EnablerIcon3} title={"Product Centric Change"} detailsIcons={[ProductDetailsIcon1, ProductDetailsIcon2, ProductDetailsIcon3, ProductDetailsIcon4]}
                                textDescription="We help organisations become product centric, through value add, customer centricity, close collaboration & continuous improvement."
                                iconDescriptions={["Centre of Excellence", "Governance & Controls", "Continuous Improvement", "AI Acceleration"]} />
                        </div>
                    </div>
                </div>

            </section>
        </GlueBaseSection>
    );
}

export default EnablerSection;