import { Carousel } from "antd"
import { FC } from "react"
import FirstSlide from "./../../assets/header/home-slide-1.webp"
import SecondSlide from "./../../assets/header/home-slide-2.webp"
import ThirdSlide from "./../../assets/header/home-slide-3.webp"
import "./header.scss"
import GlueGPT from "./../../assets/logos/glue-gpt.png"
import ReplyLight from "./../../assets/logos/reply-white.png"
import { GlueGradientButton, HeaderArrowButton } from "../common-components/buttons/button"
import { isMobile } from "../../utils/determine-mobile-tablet"
import useWindowDimensions from "../../utils/window-dimensions"


const SlideContent = {
    firstSlide:{
        heading:"Results-Driven Technology Expertise",
        subHeading:"Strategy & Architecture l Product Transformation l Integration & Automation l Data Delivery"
    },
    secondSlide:{
        icon: GlueGPT,
        heading:"Our latest AI Development",
        buttonTarget:"https://www.linkedin.com/posts/glue-reply_the-journey-of-glue-gpt-from-concept-to-activity-7203337778598658049-XLG9?utm_source=share&utm_medium=member_desktop"
    },
    thirdSlide:{
        icon: ReplyLight,
        heading:"Explore our Network",
        subHeading:"Glue Reply is part of a global network of consulting companies and agencies.",
        buttonTarget:"https://www.reply.com/en"
    }
}

export const HomepageHeaderSlide:FC = () =>{
    return(
        <>
            <Carousel autoplay style={{height:"100%"}}>
                <div className='header-slide' >
                    <div className='slide-container' style={{backgroundImage:`url(${FirstSlide})`}}>
                        <div className="slide-content">
                            <p className="slide-title">{SlideContent.firstSlide.heading}</p>
                            <p className="slide-subtitle">{SlideContent.firstSlide.subHeading}</p>
                        </div>
                    </div>
                </div>
                <div className='header-slide' >
                    <div className='slide-container' style={{backgroundImage:`url(${SecondSlide})`}}>
                        <div className="slide-content">
                            <img className="home-slide-image" src={GlueGPT} alt="GlueGPT Logo"/>
                            <p className="slide-title">{SlideContent.secondSlide.heading}</p>
                            <div><GlueGradientButton text="Find Out More" targetUrl={'https://www.linkedin.com/posts/glue-reply_the-journey-of-glue-gpt-from-concept-to-activity-7203337778598658049-XLG9?utm_source=share&utm_medium=member_desktop'} isTargetExternal={true} setTextWhite={true}/></div>
                        </div>
                    </div>
                </div>
                <div className='header-slide' >
                    <div className='slide-container' style={{backgroundImage:`url(${ThirdSlide})`}}>
                        <div className="slide-content">
                        <p className="slide-title">{SlideContent.thirdSlide.heading}</p>
                        <img className="home-slide-image" src={ReplyLight} alt="Reply Logo"/>
                        <p className="slide-subtitle">{SlideContent.thirdSlide.subHeading}</p>
                            <div><GlueGradientButton text="Find Out More" targetUrl={SlideContent.thirdSlide.buttonTarget} isTargetExternal={true} setTextWhite={true}/></div>
                        </div>
                    </div>
                </div>
            </Carousel>
            {!isMobile(useWindowDimensions().width) && <div className="arrow"><HeaderArrowButton/></div>}
        </>
    )
}