
import React, { StrictMode } from 'react';

// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from 'react-ga4'

// );
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
ReactGA.initialize('G-60CHEYET0G')
const root = createRoot(container!);
root.render(
   <StrictMode>
    <App />
  </StrictMode>,
);