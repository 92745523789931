import { FC, useEffect, useState } from "react"
import { insightCards } from "../../../model/insights-cards"
import GlueBaseSection from "../../common-components/layouts/base-section"
import { InsightCarousel } from "../../common-components/carousels/mobile-carousels/insight-carousel"
import { ContentType } from "../../../model/filter-options"
import BGInsight from './../../../assets/insights/insight-collection-bg.jpg'
import './insights-by-category.scss'
import { fetchInsights } from "../../backend-services/insightsService"

export const CategoryInsightSection:FC = () => {
    const [whitePaperInsights, setWhitePaperInsights] = useState(insightCards.insights.filter(insights => insights.insightType === ContentType.WhitePaper))
    const [bestPracticesInsights, setBestPracticesInsights] = useState(insightCards.insights.filter(insights => insights.insightType === ContentType.BestPractices))
    const [videoInsights, setVideoInsights] = useState(insightCards.insights.filter(insights => insights.insightType === ContentType.Video))
    const [articleInsights, setArticleInsights] = useState(insightCards.insights.filter(insights => insights.insightType === ContentType.Article))

    useEffect(()=>{
        fetchInsights(6,0,true,false, false,ContentType.WhitePaper).then((data) => setWhitePaperInsights(data.insights))
        fetchInsights(6,0,true,false,false,ContentType.BestPractices).then((data) => setBestPracticesInsights(data.insights))
        fetchInsights(6,0,true,false, false,ContentType.Video).then((data) => setVideoInsights(data.insights))
        fetchInsights(6,0, true,false,false, ContentType.Article).then((data) => setArticleInsights(data.insights))
    },[])

    return(
        <GlueBaseSection backgroundImage={BGInsight} applyBackgroundBlackOverlay={false}>
            <h1 className="insight-collection-title"> Our Collections </h1>
            <div className="insight-categories-wrapper">
                {whitePaperInsights.length > 3 && 
                    <>
                        <h2>White Paper</h2>
                        <InsightCarousel insightCards={whitePaperInsights}/>
                    </>
                }
                
                {bestPracticesInsights.length > 3 && 
                    <>
                        <h2>Best Practices</h2>
                        <InsightCarousel insightCards={bestPracticesInsights}/>
                    </>
                }
               
                {videoInsights.length > 3 && 
                    <>
                        <h2>Video</h2>
                        <InsightCarousel insightCards={videoInsights}/>
                    </>
                }
                
                {articleInsights.length > 3 && 
                    <>
                        <h2>Articles</h2>
                        <InsightCarousel insightCards={articleInsights}/>
                    </>
                }
            </div>
        </GlueBaseSection>
    )
}