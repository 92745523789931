import {FC} from 'react'
import './header.scss'
import ServicesBG from '../../assets/header/services-header-bg.webp'
import OurWorkBG from '../../assets/header/our-work-header-bg.webp'
import InsightsBG from '../../assets/header/insights-header-bg.webp'
import CareersBG from '../../assets/header/careers-header-bg.webp'
import AboutUsBG from '../../assets/header/about-us-header-bg.webp'
import PeopleBG from '../../assets/header/people-header-bg.webp';
import EnvironmentBG from '../../assets/header/environment-header-bg.webp'
import InsightMobileBG from '../../assets/header/insights-mobile-header.webp'
import PeopleMobileBg from '../../assets/header/people-mobile-header.webp'
import { GluePages } from '../../model/glue-pages'
import { HomepageHeaderSlide } from './homepage-slider'
import { HeaderArrowButton } from '../common-components/buttons/button'
import { isMobile } from '../../utils/determine-mobile-tablet'
import useWindowDimensions from '../../utils/window-dimensions'
// import styled from "styled-components"


interface HeaderProps {
    headerLocation?: number
}

const headerContent = {
    contents:[
        {
            headerBG: ServicesBG,
            headerTitle: "OUR SERVICES",
            headerSubtitle: "Explore how we navigate discovery, design, development, and support services to make digital transformation effortless"    
        },
        {
            headerBG: OurWorkBG,
            headerTitle: "OUR WORK",
            headerSubtitle: "We work collaboratively with our clients. Find out more through our case studies"    
        },
        {
            headerBG: InsightsBG,
            headerTitle: "INSIGHTS",
            headerSubtitle: "Hear from our industry experts… "    
        },
        {
            headerBG: CareersBG,
            headerTitle: "CAREERS",
            headerSubtitle: "Your Career at Glue Reply"    
        },
        {
            headerBG: AboutUsBG,
            headerTitle: "ABOUT GLUE",
            headerSubtitle: ""   
        },
        {
            headerBG: PeopleBG,
            headerTitle: "PEOPLE",
            headerSubtitle: ""    
        },
        {
            headerBG: EnvironmentBG,
            headerTitle: "ENVIRONMENT",
            headerSubtitle: "Learn about our sustainability initiatives and find out how we’re advancing our eco-friendly approach within our practices "    
        },
    
    ]
    
}

export const GlueHeader: FC<HeaderProps> = (props) => {
    var bgImageUrl = ""
    var headerTitle = ""
    var headerSubtitle = ""


    const isMobileMode = isMobile(useWindowDimensions().width)
    headerContent.contents[2].headerBG = isMobileMode ? InsightMobileBG : InsightsBG
    headerContent.contents[5].headerBG =  isMobileMode ? PeopleMobileBg : PeopleBG

    
    switch (props.headerLocation){
        case GluePages.SERVICES:
            bgImageUrl = headerContent.contents[0].headerBG
            headerTitle = headerContent.contents[0].headerTitle
            headerSubtitle = headerContent.contents[0].headerSubtitle
            break
        case GluePages.WORK:
            bgImageUrl = headerContent.contents[1].headerBG
            headerTitle = headerContent.contents[1].headerTitle
            headerSubtitle = headerContent.contents[1].headerSubtitle
            break
        case GluePages.INSIGHTS:
            bgImageUrl = headerContent.contents[2].headerBG
            headerTitle = headerContent.contents[2].headerTitle
            headerSubtitle = headerContent.contents[2].headerSubtitle
            break
        case GluePages.CAREERS:
            bgImageUrl = headerContent.contents[3].headerBG
            headerTitle = headerContent.contents[3].headerTitle
            headerSubtitle = headerContent.contents[3].headerSubtitle
            break
        case GluePages.COMPANY:
            bgImageUrl = headerContent.contents[4].headerBG
            headerTitle = headerContent.contents[4].headerTitle
            headerSubtitle = headerContent.contents[4].headerSubtitle
            break
        case GluePages.PEOPLE:
            bgImageUrl = headerContent.contents[5].headerBG
            headerTitle = headerContent.contents[5].headerTitle
            headerSubtitle = headerContent.contents[5].headerSubtitle
            break
        case GluePages.ENVIRONMENT:
            bgImageUrl = headerContent.contents[6].headerBG
            headerTitle = headerContent.contents[6].headerTitle
            headerSubtitle = headerContent.contents[6].headerSubtitle
            break
        default:
            bgImageUrl = ServicesBG

    }

// interface TransientStyleCardProps {
//         $backgroundImage: string
// }
    
// const StyledCardContentContainer = styled.div.attrs({className: "header-image-wrapper"})<TransientStyleCardProps>`
//         background: url(${p => p.$backgroundImage}),linear-gradient(rgba(255,255,255,0.9),rgba(0,0,0,0.7));
//         background-blend-mode: overlay;
// `




    return(
        <>
            {props.headerLocation === GluePages.HOMEPAGE && <header className='glue-header' style={{backgroundImage:`url(${bgImageUrl})`}}>
                <HomepageHeaderSlide/>
            </header>}
            {props.headerLocation !== GluePages.HOMEPAGE && <header className='glue-header' style={{backgroundImage:`url(${bgImageUrl})`}}>
                
            <div className='header-banner' >
                <div className='header-container'>
                    <div className="header-content">
                        <h1 className="slide-title">{headerTitle}</h1>
                        <h2 className="slide-subtitle">{headerSubtitle}</h2>
                        <div className='header-arrow'><HeaderArrowButton/></div>
                    </div>
                </div>
               
            </div>
            </header>}
            
        </>
    )
}






