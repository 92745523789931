import {FC} from 'react'
import Slider, { Settings } from 'react-slick'
import './clients-carousel.scss'
import DBSLogo from './../../../assets/logos/dbs-logo-small.png'
import LQLogo from './../../../assets/logos/landq-small.png'
import NHSLogo from './../../../assets/logos/nhs-small.png'
import HOLogo from './../../../assets/logos/ho-small.png'
import STCLogo from './../../../assets/logos/stc-logo.png'
import { isMobile } from '../../../utils/determine-mobile-tablet'
import useWindowDimensions from '../../../utils/window-dimensions'

const imageSlides = [{src: DBSLogo},{src: LQLogo},{src: NHSLogo},{src: HOLogo},{src: STCLogo}]

type ClientLogoSlideProps = {
    image: string;
}


const ClientLogoSlide: FC<ClientLogoSlideProps> = ({image}) => {
    return(
        <>
            <div className='client-slide-container'>
                <img className='client-logo' src={image} alt='client-logo'/>
            </div>
        </>
    )
}

const ClientCarousel:FC = () => {
    

    var carouselSettings: Settings = {
        infinite: true,
        slidesToShow: isMobile(useWindowDimensions().width) ? 2 : 4,
        centerMode: false,
        arrows: false,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1000,
        cssEase: "linear"
    };


    return(
        <>
            <div className="clients-carousel-wrapper">
                <div className='clients-carousel-container'>
                    <Slider {...carouselSettings}>
                    {imageSlides.map((image, index) => (
                        <ClientLogoSlide image={image.src} key={'client-slide-'+index}/>
                    ))}
                    </Slider>
                </div>
            </div>

        </>
    )
}

export default ClientCarousel