import  { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

interface BurgerProps {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
}



const StyledBurger = styled.button.attrs({className:"burger-container"})<{$open:boolean}>`
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${p => p.$open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      opacity: ${p => p.$open? '0' : '1'};
      transform: ${p => p.$open ? 'translateX(20px)' : 'translateX(0)'};
    }

    &:nth-child(3) {
      transform: ${p => p.$open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`

export const Burger: FC<BurgerProps> = ({ open, setOpen, ...props }) => {
  
  const isExpanded = open ? true : false;
  
  return (
    <StyledBurger aria-label="Toggle menu" aria-expanded={isExpanded} $open={open} onClick={() => setOpen(!open)} {...props}>
      <div/>
      <div/>
      <div />
    </StyledBurger>
  )
}
