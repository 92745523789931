import { FC } from "react";
import GlueBaseSection from "../common-components/layouts/base-section";
import './our-partners-section.scss';
import Mulesoft from './../../assets/our-work/mulesoft.png';
import Boomi from './../../assets/our-work/boomi.png';
import Software from './../../assets/our-work/software.png';
import Worktato from './../../assets/our-work/worktato.png';
import Kong from './../../assets/our-work/kong.png';
import Snowflake from './../../assets/our-work/snowflake.png';
import { CompanySectionId } from "../../model/section-ids";

// List of image sources and corresponding links
const partnerData = [
  { image: Mulesoft, link: "https://www.mulesoft.com" },
  { image: Boomi, link: "https://www.boomi.com" },
  { image: Software, link: "https://www.softwareag.com" },
  { image: Worktato, link: "https://www.workato.com" },
  { image: Kong, link: "https://www.konghq.com" },
  { image: Snowflake, link: "https://www.snowflake.com" }
];

export const OurPartnersSection: FC = () => {
    return (
        <>
            <GlueBaseSection id={CompanySectionId.AboutReply}>
                <div className="partner-container">
                    <h1>OUR PARTNERS</h1>
                    <div className='partners-wrapper'>
                        {partnerData.map((partner, index) => (
                            <a key={index} href={partner.link} target="_blank" rel="noopener noreferrer">
                                <img src={partner.image} alt={`partner-${index}`} />
                            </a>
                        ))}
                    </div>
                </div>
            </GlueBaseSection>
        </>
    );
};
