import ReactGA from  'react-ga4'


export const sendPageEvent = (path: string, title: string, cookies: any) => {
    

    if(cookies.cookieConsent)
    {
        ReactGA.send({
            hitType: "pageview",
            page: {path},
            title: {title},
          });
    }
}