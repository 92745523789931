import { InsightArrayType } from "./insights";
import WhitePaper from './../assets/insights/white-paper.png'
import WhitePaper1 from './../assets/insights/white-paper-1.jpg'
import Article from './../assets/insights/article.png'
import BestPractices from './../assets/insights/best-practices-1.jpg'
import { ContentType, ServicesOptions } from "./filter-options";

export const insightCards: InsightArrayType = {
    insights: [
        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Article,
            serviceType: ServicesOptions.Strategy,
            cornerText: ""
        },

        {
            image: BestPractices,
            borderAndText: "#7353A7",
            linkText:"Dealing with Mass Automation",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.BestPractices,
            serviceType: ServicesOptions.IntegrationAutomation,
            cornerText: ""
        },

        {
            image: WhitePaper1,
            borderAndText: "#2B763B",
            linkText:"Unlocking AI Readiness with Business Capability Modelling",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.WhitePaper,
            serviceType: ServicesOptions.ProductDesign,
            cornerText: ""
        },
        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Video,
            serviceType: ServicesOptions.DataDelivery,
            cornerText: ""
        },
        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Article,
            serviceType: ServicesOptions.Strategy,
            cornerText: ""
        },

        {
            image: BestPractices,
            borderAndText: "#7353A7",
            linkText:"Dealing with Mass Automation",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.BestPractices,
            serviceType: ServicesOptions.IntegrationAutomation,
            cornerText: ""
        },

        {
            image: WhitePaper,
            borderAndText: "#2B763B",
            linkText:"Unlocking AI Readiness with Business Capability Modelling",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.WhitePaper,
            serviceType: ServicesOptions.ProductDesign,
            cornerText: ""
        },
        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Video,
            serviceType: ServicesOptions.DataDelivery,
            cornerText: ""
        },

        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Article,
            serviceType: ServicesOptions.Strategy,
            cornerText: ""
        },

        {
            image: BestPractices,
            borderAndText: "#7353A7",
            linkText:"Dealing with Mass Automation",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.BestPractices,
            serviceType: ServicesOptions.IntegrationAutomation,
            cornerText: ""
        },

        {
            image: WhitePaper,
            borderAndText: "#2B763B",
            linkText:"Unlocking AI Readiness with Business Capability Modelling",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.WhitePaper,
            serviceType: ServicesOptions.ProductDesign,
            cornerText: ""
        },
        {
            image: Article,
            borderAndText: "#008395",
            linkText:"Leveraging Cleantech for Business Growth: The Role of Integration Platforms",
            linkTarget:"https://www.reply.com/en",
            insightType: ContentType.Video,
            serviceType: ServicesOptions.DataDelivery,
            cornerText: ""
        },

        ],
    totalCount: 10
}