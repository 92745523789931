import React from "react";
import BaseCard from "./base-card";

interface ProductTransformationProps {
    imageSrc: string;
    description: string;
    title: string;
    extraDetails: string;
    detailsIcons: Array<string>;
    iconDescriptions: Array<string>;
    position: boolean; // Not needed anymore, can be removed
}

export const RightCard: React.FC<ProductTransformationProps> = (props) => {
    return <BaseCard {...props} position="right" />;
};

export const LeftCard: React.FC<ProductTransformationProps> = (props) => {
    return <BaseCard {...props} position="left" />;
};
