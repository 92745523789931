import { FC } from "react";
import styled from "styled-components";
import { GlueGradientButton } from "../../buttons/button";
import './capability-card.scss'

type CapabilityCardProps = {
    title:string;
    targetServiceSectionId: string;
    backgroundImage: string;
}

interface TransientStyledProps {
    $backgroundImage: string
}

const StyledCapabilityCard = styled.div.attrs({className:'capability-card'})<TransientStyledProps>`
        
        width: 100%;
        height: 450px;
        p{font-size: 30px;}
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: "AlaskaMediumContrast";
        justify-content: space-between;
        background: url(${p => p.$backgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        h2{
            font-family: "AlaskaLightContrast";
        }
        @media (max-width: 1000px){
            p{font-size: 25px;}
        }
    `

export const CapabilityCard: FC<CapabilityCardProps> = ({backgroundImage, targetServiceSectionId, title}) => {
    

    return(
        <>
            <StyledCapabilityCard $backgroundImage={backgroundImage}>
                <h2 style={{width:"90%"}}>
                    {title}
                </h2>
                <GlueGradientButton text="Find Out More" targetUrl="/glue-services" scrollToTargetContainer={targetServiceSectionId} isTargetExternal={false} setTextWhite={true}/>
            </StyledCapabilityCard>
        </>
    )

}
