import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ContactButton } from "../../navbar";
import { GlueStateContext } from "../../../../utils/context/glue-context";


const StyledMenu = styled.nav.attrs({ className: "mobile-menu" })<{
  $open: boolean;
  $dropDownActive: boolean;
}>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  transform: ${(p) => (p.$open ? "translateY(0)" : "translateY(-100%)")};
  text-align: center;
  padding: 100px 0 2rem 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  overflow-y: scroll;

  .mobile-menu-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    padding-bottom: 0rem;
  }

  a {
    font-size: 25px;
    font-family: "AlaskaMediumContrast";
    padding: 1rem 0;
    letter-spacing: 0.2rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
  }

  .mobile-nav-dropdown-button {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-family: "AlaskaMediumContrast";
    letter-spacing: 0.2rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s linear;
    padding: 1rem;
    .fa-icon-right {
      transition: 0.3s ease-out;
      transform: ${(p) => (p.$dropDownActive ? "rotate(90deg)" : "rotate(0deg)")};
    }
  }

  .mobile-dropdown-menu {
    color: white;
    display: flex;
    visibility: ${(p) => (p.$dropDownActive ? "visible" : "hidden")};
    height: 100%;
    transition: 0.3s;
    flex-direction: column;
    align-items: center;
    animation: ${(p) => (p.$dropDownActive ? "animateMobileDD" : "animateMobileDDOut")};
    animation-duration: 0.5s;
    justify-content: flex-start;

    a {
      font-size: 20px;
      padding: 0.5rem 0;
    }
  }

  .contact-button {
    width: 200px;
    margin: 2rem 0 5rem 0;
  }
`;

interface MobileTabletMenuProps {
  open: boolean;
}

const MobileTabletMenu: FC<MobileTabletMenuProps> = ({ open }) => {
  const [isDropDownActive, setIsDropdownActive] = useState(false);
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const { glueState } = useContext(GlueStateContext) || {}; // Ensure context is handled properly

  // Function to handle navigation and scrolling
  const handleNavigation = (url: string) => {
    glueState.elementToScroll = "navbar";
    window.scrollTo(0, 0);
  };

  // Dropdown toggle function
  const toggleDropdown = () => {
    setIsDropdownActive(!isDropDownActive);
  };

  // Prevent scrolling when the menu is open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component is unmounted or when `open` changes
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  return (
    <StyledMenu
      $open={open}
      aria-hidden={!isHidden}
      tabIndex={tabIndex}
      $dropDownActive={isDropDownActive}
    >
      <div className="mobile-menu-content">
        <NavLink
          to="/glue-services"
          className="nav-item-link"
          onClick={() => handleNavigation("/glue-services")}
        >
          Services
        </NavLink>
        <NavLink
          to="/glue-our-work"
          className="nav-item-link"
          onClick={() => handleNavigation("/glue-our-work")}
        >
          Our Work
        </NavLink>
        <NavLink
          to="/glue-insights"
          className="nav-item-link"
          onClick={() => handleNavigation("/glue-insights")}
        >
          Insights
        </NavLink>
        <NavLink
          to="/glue-careers"
          className="nav-item-link"
          onClick={() => handleNavigation("/glue-careers")}
        >
          Careers
        </NavLink>

        <div className="mobile-nav-dropdown-button" onClick={toggleDropdown}>
          <span>
            About Us &nbsp;<FontAwesomeIcon icon={faChevronRight} className="fa-icon-right" />
          </span>
        </div>

        {isDropDownActive && (
          <div className="mobile-dropdown-menu">
            <NavLink
              to="/glue-people"
              onClick={() => handleNavigation("/glue-people")}
            >
              People
            </NavLink>
            <NavLink
              to="/glue-environment"
              onClick={() => handleNavigation("/glue-environment")}
            >
              Environment
            </NavLink>
            <NavLink
              to="/glue-company"
              onClick={() => handleNavigation("/glue-company")}
            >
              Company
            </NavLink>
          </div>
        )}

        {/* Contact Button is now back in its original position */}
      </div>
      
      <ContactButton
        text="Contact us"
        iconSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/f8db7f0046e5e78b47cebe45d7739a5ecb669b6025a0ba3efea61f7481d3f65e?apiKey=4641169c81374835b0d9ae8f2ea89954&"
        setOpenContact={glueState.setOpenContact!}
      
      />
    </StyledMenu>
  );
};

export default MobileTabletMenu;
