import {FC, useEffect} from 'react'
import GluePage from '../../components/glue-page/page'
import { GluePages } from '../../model/glue-pages'
import ContinuousLearning from '../../components/people-page/continuous-learning-comp/continuous-learning'
import LifeAtGlue from '../../components/people-page/life-at-glue-comp/life-at-reply'
import EventsAndSocial from '../../components/people-page/event-and-social-comp/event-and-social'
import Diversity from '../../components/people-page/diversity-comp/diversity'
import { OurPeopleSection } from '../../components/people-page/our-people-comp/our-people'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../../utils/analyticsRequest'


export const GluePeople: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-people", "People", cookies)    
      });
    return(
        <GluePage headerLocation={GluePages.PEOPLE}>
            <OurPeopleSection/>
            <ContinuousLearning/>
            <Diversity/>
            <LifeAtGlue/>
            <EventsAndSocial/>
        </GluePage>
    )
}