import {FC, useEffect} from 'react'
import GluePage from '../components/glue-page/page'
import { GluePages } from '../model/glue-pages'
import { WaysOfWorking } from '../components/our-work-page/ways-of-working-section'
import { ClientsSection } from '../components/our-work-page/our-clients-section'
import { CaseStudiesSection } from '../components/our-work-page/case-studies-section'
import { OurPartnersSection } from '../components/our-work-page/our-partners-section'
import { useCookies } from 'react-cookie'
import { sendPageEvent } from '../utils/analyticsRequest'

export const GlueWork: FC = () => {
    const [cookies] = useCookies(["cookieConsent","cookieDisabled"]);

    useEffect(() => {
        sendPageEvent("glue-work", "OurWork", cookies)    
      });
    return(
        <GluePage headerLocation={GluePages.WORK}>
            <WaysOfWorking/>
            <ClientsSection/>
            <CaseStudiesSection/>
            <OurPartnersSection/>
        </GluePage>

    )
}