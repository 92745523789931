import {FC} from 'react'
import GlueBaseSection from '../common-components/layouts/base-section'
import ClientCarousel from '../common-components/carousels/clients-carousel'

const OurClientsHome:FC = () => {


    return(
        <>
            <GlueBaseSection>
                <h1 style={{textAlign: 'center', paddingTop:'3rem'}}> OUR LONG TERM CLIENTS </h1>
                <ClientCarousel/>
            </GlueBaseSection>
        
        
        </>
        
    )
}


export default OurClientsHome