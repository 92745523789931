import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { ContentType, ServicesOptions } from "../../../model/filter-options"
import { insightCards } from "../../../model/insights-cards"
import { Pagination, Select } from "antd"
import GlueBaseSection from "../../common-components/layouts/base-section"
import { GlueGradientButton } from "../../common-components/buttons/button"
import InsightCard from "../../common-components/content-cards/insights-cards/insight-card"
import Bin from './../../../assets/icons/bin.png'
import { InsightsType } from "../../../model/insights"
import './mobile-filtered-insights.scss'
import { fetchInsights } from "../../backend-services/insightsService"
import React from "react"

const FilterOptions = {
    Services: [ServicesOptions.Strategy, ServicesOptions.ProductDesign, ServicesOptions.IntegrationAutomation, ServicesOptions.DataDelivery],
    Content: [ContentType.WhitePaper, ContentType.Article, ContentType.BestPractices, ContentType.Video, ContentType.Brochure]
  }
  
  interface ServiceDropDownProps {
    setOption: Dispatch<SetStateAction<ServicesOptions>>
    value: ServicesOptions
  }
  
  interface ContentDropDownProps {
    setOption: Dispatch<SetStateAction<ContentType>>
    value: ContentType
  }
  
  
  const ServiceFilterDropdown: FC<ServiceDropDownProps> = ({setOption,value}) => {
  
  
    
    return(
        <Select
        defaultValue={ServicesOptions.NoSelection}
        options={FilterOptions.Services.map((service) => ({ label: service, value: service }))}
        onChange={(option)=>{setOption(option)}}
        value={value}
        />
    )
  }
  
  const ContentTypeDropdown: FC<ContentDropDownProps> = ({setOption, value}) => {
    return(
        <Select
        defaultValue={ContentType.NoSelection}
        options={FilterOptions.Content.map((content) => ({ label: content, value: content }))}
        onChange={(option)=>{setOption(option)}}
        value={value}
        />
    )
  }

type InsightListProp = {
    insights: InsightsType[]
}


const FilteredList:FC<InsightListProp>= ({insights}) => {
    return(
        <GlueBaseSection>
            {insights.length > 0 && <div className='mobile-insights-cards-container'>
                {insights.map((insight, key)=> 
                    <InsightCard key={key} image={insight.image} borderAndTextColor={insight.borderAndText} cornerText={insight.insightType} linkText={insight.linkText} linkTarget={insight.linkTarget}/>
                )}
            </div>}
        </GlueBaseSection>
    )
}


export const MobileFilteredInsights: FC = () => {
    const [serviceOption, setServiceOption] = useState(ServicesOptions.NoSelection)
    const [contentOption, setContentOption] = useState(ContentType.NoSelection)
    const [filteredInsights, setFilteredInsights] = useState(insightCards.insights)
    const [paginationValue, setPaginationValue] = useState(0)
    const [current, setCurrent] = useState(1)
    const [searchInput, setSearchInput] = useState("")
    const requestInsights = async (page:number) => {
      
      const offsetValue = 9 * (page - 1)
      setCurrent(page)
      
      try {
        let insights = await fetchInsights(9,offsetValue, true,false); // Fetch default limit
        // );
        setFilteredInsights(insights.insights);
        setPaginationValue(insights.totalCount)
        
      } catch (error) {
        console.error("Error fetching filtered insights:", error);
        setFilteredInsights([]);
      }
    };
    
      useEffect(() => {
        requestInsights(0)
      },[])
  
    const requestFilteredInsights = async (page: number) => {
      const filtersSection = document.querySelector('#mobile-filters')
      filtersSection?.scrollIntoView({behavior:'auto'})
      const offsetValue = 9 * (page - 1)
      setCurrent(page)
        try {
          const applyServiceFilter = serviceOption === ServicesOptions.NoSelection ? undefined : serviceOption
          const applyContentFilter = contentOption === ContentType.NoSelection ? undefined : contentOption
          const applySearchFilter = searchInput === '' ? undefined : searchInput
          await fetchInsights(9, offsetValue,true,false,false, applyContentFilter, applyServiceFilter, applySearchFilter)
          .then((data) => {setFilteredInsights(data.insights); setPaginationValue(data.totalCount)})// Fetch default limit
    
        } catch (error) {
          console.error("Error fetching filtered insights:", error);
          setFilteredInsights([]);
        } 
      };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(e.target.value);
    };
  
    const resetInsights = () => {
      requestInsights(0)
      setSearchInput("")
      setServiceOption(ServicesOptions.NoSelection)
      setContentOption(ContentType.NoSelection)
    }

  
  
    return (
        <>
            <GlueBaseSection id="mobile-filters">
            <h1 style={{marginTop: "4rem"}}>Explore All Insights</h1>
            <div className='mobile-filters-wrapper' id="mobile-filters">
                <div className='mobile-form-fields-container'>
                    <ServiceFilterDropdown setOption={setServiceOption} value={serviceOption}/>
                    <ContentTypeDropdown setOption={setContentOption} value={contentOption}/>
                    <input value={searchInput} className='search-input' placeholder='Search' onChange={(input)=>{handleSearchInputChange(input)}}/>
                    
                </div>
    
            <div className='mobile-buttons-filter-container' >
            <GlueGradientButton text={"Apply Filters"} targetUrl={''} isTargetExternal={false} setTextWhite={true} onClickEvent={requestFilteredInsights}/>
                <button className='reset-button' onClick={()=>{resetInsights()}}><img src={Bin} alt="clear filters"/>
                &nbsp; &nbsp;Clear all filters</button>
            </div>
    
            </div>
            </GlueBaseSection>
            {paginationValue > 9 && <div className='pagination-container'>
          <Pagination pageSize={9} showSizeChanger={false} align='center' current={current} onChange={(page) => {requestFilteredInsights(page)}} total={paginationValue+9}/>
        </div>}
            <FilteredList insights={filteredInsights}/>
            {paginationValue > 9 && <div className='pagination-container'>
          <Pagination pageSize={9} showSizeChanger={false} align='center' current={current} onChange={(page) => {requestFilteredInsights(page)}} total={paginationValue+9}/>
        </div>}
        </>
    )
  }