import { FC } from 'react'
import Slider, { Settings } from 'react-slick'
import './people-carousel.scss'
import Innovation from './../../../assets/people-page/carousel/innovation.png'
import Teamwork from './../../../assets/people-page/carousel/teamwork.png'
import Excellence from './../../../assets/people-page/carousel/excellence.png'
import Customer from './../../../assets/people-page/carousel/customer.png'
import Speed from './../../../assets/people-page/carousel/speed.png'

import styled from 'styled-components'
import { isMobile } from '../../../utils/determine-mobile-tablet'
import useWindowDimensions from '../../../utils/window-dimensions'

const imageSlides = [{ src: Innovation }, { src: Teamwork }, { src: Excellence }, { src: Customer }, { src: Speed }]

type PeopleSlideProps = {
    image: string,
    title: string,
    description: string
}

interface TransientStyleCardContentProps {
    $image: string
}

const StyledCardContentContainer = styled.div.attrs({ className: "styled-slide-component" }) <TransientStyleCardContentProps>`
        position:inherit;
        width: 100%;
        height: 400px;
        background: url(${props => props.$image}),linear-gradient(rgba(255,255,255,0.2),rgba(255,255,255,0.2));
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        border-radius: 1rem;
        display:flex;
        align-items:center;
        justify-content:center;  
    `

const PeopleSlide: FC<PeopleSlideProps> = ({ image, title, description }) => {
    return (
        <>
            <div className='people-slide-container'>
                <StyledCardContentContainer $image={image}>
                    <div className='description-container'>
                        <p className='people-desc'>{description}</p>
                    </div>
                </StyledCardContentContainer>

                <p className='people-sub'> {title} </p>
            </div>
        </>
    )
}

const PeopleCarousel: FC = () => {
    const isMobileMode = isMobile(useWindowDimensions().width)

    var carouselSettings: Settings = {
        className: "people-carousel",
        infinite: true,
        speed: 500,
        slidesToShow: isMobileMode ? 1 : 3,
        arrows: isMobileMode ? false : true,
        centerMode: true,
        adaptiveHeight: true,
        centerPadding: "60px"
    };

    //const desc = "With a hands-on approach, we apply our imagination and innovative abilities to implement sustainable solutions"
    const descriptions =
        [
            "With a hands-on approach, we apply our imagination and innovative abilities to implement sustainable solutions",
            "Transparency, honesty, ethics and diversity allow us to discover unique ways of collaborating",
            "Our academic culture, analytical attitude and passion for excellence empower us to provide a level of service that is second to none",
            "We  offer our customers reliability, a sense of responsibility, and an uncompromising commitment to quality. Their aims are our aims, and we always strive to exceed them",
            "To break new ground, we need to think fast, act fast, and move fast",

        ]
    //const title = "TITLEPLACEHOLDER"
    const titles = ["Innovation", "Teamwork", "Excellence", "Customer", "Speed", ]

    return (
        <>
            <div className="people-carousel-wrapper">
                <div className='people-carousel-container'>
                    <Slider {...carouselSettings}>
                        {imageSlides.map((image, index) => (
                            <PeopleSlide key={index} image={image.src} title={titles[index]} description={descriptions[index]} />
                        ))}
                    </Slider>
                </div>
            </div>

        </>
    )
}

export default PeopleCarousel