import { FunctionComponent, useContext } from "react";
import "./ideal-role-comp.scss";
import { GlueStateContext } from "../../../utils/context/glue-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const OpenPositions: FunctionComponent = () => {
  const {glueState} = useContext(GlueStateContext)
  return (
    <div className="root">
      <section className="dont-see-your-container">
        <span className="dont-see-your-content">
          <h2 className="dont-see-your-ideal-role">Don’t see your ideal role?</h2>
          <p className="middle-content">
            We are always interested in hearing from new talent. <br />
            If you can’t see your desired position, please get in touch and let’s discuss our opportunities.
          </p>
          <FontAwesomeIcon className="mail-button" icon={faEnvelope} onClick={() =>{glueState.formTitle="Get in touch"; glueState.setOpenContact!(true)}}/>
        </span>
      </section>
    </div>
  );
};

export default OpenPositions;
